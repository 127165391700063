import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import Color from 'color';

const { fontSizeLarge, generalBorderRadius, inputBorderColor } = VARIABLES;
const { greyText } = THEME_COLORS;

export const css = {
  select: {
    height: '3.1875rem !important',
    '& .ant-select-selector': {
      fontSize: `${fontSizeLarge} !important`,
      borderRadius: `${generalBorderRadius} !important`,
      padding: '0 1.25rem !important',
      height: '3.1875rem !important',
      color: greyText,
      '& .ant-select-selection-placeholder': {
        lineHeight: '3rem !important',
      },
      '& .ant-select-selection-search-input': {
        height: '3rem !important',
      },
      '& .ant-select-selection-item': {
        lineHeight: '3rem !important',
      },
    },
    '&:hover, &:active': {
      '& .ant-select-selector:not(.act-select-disabled)': {
        borderColor: `${inputBorderColor} !important`,
      },
    },
    '&:focus, &.ant-select-focused': {
      '& .ant-select-selector:not(.act-select-disabled)': {
        borderColor: `${inputBorderColor} !important`,
        boxShadow: `0 0 0 2px ${Color(inputBorderColor).rgb().fade(0.8).string()} !important`,
      },
    },
    '& .ant-select-selection__rendered': {
      lineHeight: '24px !important',
      margin: 0,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    select: {},
  },
};

export default css;
