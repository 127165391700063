import { ReactElement, ReactNode, useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { cacheService as Storage } from '@brainysoft/lk-components';

type CaptchaResponseT = {
  captcha_required: boolean;
  captcha_site_key: string;
};

type UseCaptchaT = {
  interceptor: <ResponseT = Record<string, unknown> | undefined>(response: CaptchaResponseT | ResponseT) => ResponseT;
  showCaptcha: boolean;
  CAPTCHA: () => ReactElement;
};
type UseCaptchaHookT = (props: { onRecaptchaProof: (key) => Promise<void> }) => UseCaptchaT;

export const useCaptcha: UseCaptchaHookT = (props) => {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [siteKey, setSiteKey] = useState<string | undefined>();

  const interceptor = useCallback((response) => {
    if (response && response?.captcha_required && response?.captcha_site_key) {
      setSiteKey(response?.captcha_site_key);
      setShowCaptcha(true);
      return undefined;
    } else {
      setShowCaptcha(false);
      return response;
    }
  }, []);

  const onRecaptchaProof = useCallback(
    async (key) => {
      await SetStorageRecaptchaKey(key);
      setShowCaptcha(false);

      if (typeof props.onRecaptchaProof === 'function') await props.onRecaptchaProof(key);
    },
    [props.onRecaptchaProof]
  );

  const CAPTCHA = useCallback(() => {
    if (!siteKey) return <></>;
    return <ReCAPTCHA sitekey={siteKey} onChange={onRecaptchaProof} hl={'ru'} />;
  }, [siteKey, onRecaptchaProof]);
  return {
    interceptor,
    showCaptcha,
    CAPTCHA: CAPTCHA,
  };
};

export const SetStorageRecaptchaKey = async (key: string): Promise<void> => {
  await Storage.setItem('recaptcha:key', key);
};

export const GetStorageRecaptchaKey = async (): Promise<string | undefined> => {
  return (await Storage.getItem('recaptcha:key')) as Promise<string | undefined>;
};
