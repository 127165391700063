import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { MapToken } from 'antd/es/theme/interface/maps';
import { pxStringToNumber } from '@brainysoft/lk-custom-kernel/utils/style-units-converters';

const { primary, red, green, greyLight } = THEME_COLORS;
const { fontFamily, fontSizeBase, fontSizeLarge, fontSizeSmall, generalBorderRadius } = VARIABLES;

export const ANTD_THEME: Partial<MapToken> = {
  //
  // fonts
  //
  fontFamily: fontFamily,
  fontSize: pxStringToNumber(fontSizeBase),
  fontSizeLG: pxStringToNumber(fontSizeLarge),
  fontSizeSM: pxStringToNumber(fontSizeSmall),

  //
  // colors
  //
  colorPrimary: primary,
  colorError: red,
  colorSuccess: green,

  //
  // borders
  //
  borderRadius: pxStringToNumber(generalBorderRadius),
  colorBorder: greyLight,
};
