import { SCREEN_SIZE } from '@brainysoft/lk-components';
import {
  authBackground,
  generalBorder,
  generalBorderLight,
  generalBorderRadius,
  maxWidth,
} from '@brainysoft/lk-custom/variables';
import { greyText, pageBackground, primary, white } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  pageWrap: {
    background: white,
    borderRadius: generalBorderRadius,
    width: '100%',
    flex: 1,
  },
  pageContent: {
    margin: 0,
  },
  pageTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: greyText,
    // borderBottom: `solid 1px ${greyLight}`,
    padding: '0 0 1.5rem',
    margin: 0,
    position: 'relative',
    '& + .pageContent': {
      paddingTop: '1.5rem',
    },
  },
  wideLayoutPageTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: greyText,
    borderBottom: generalBorderLight,
    padding: '1.5rem 2rem',
    margin: 0,
    position: 'relative',
    '& + .pageContent': {
      paddingTop: '1.5rem',
    },
  },
  wideLayoutPageSubtitle: {
    padding: '0 2rem',
  },
  backBtn: {
    position: 'absolute',
    left: 16,
    top: 20,
  },
  generalWrapper: {
    background: pageBackground,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '&.hasFooter': {
      '& .contentSectionWrapper': {
        padding: '1.875rem 2rem',
        background: white,
        borderRadius: generalBorderRadius,
        flex: 1,
      },
      '& $leftSide': {
        padding: '1.875rem 1rem 1.875rem 2rem',
      },
      '& $rightSide': {
        background: 'transparent',
        padding: '1.875rem 2rem 1.875rem 1rem',
      },
    },
  },
  generalWrapperExpanded: {
    background: white,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '&.hasFooter': {
      '& .contentSectionWrapper': {
        padding: '1.875rem 2rem',
        background: white,
        borderRadius: generalBorderRadius,
        flex: 1,
      },
    },
  },
  registrationPageWrap: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '2rem',
  },
  contentWrapper: {
    maxWidth: maxWidth,
    margin: '0 auto',
    flex: 1,
    flexWrap: 'wrap',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    padding: 0,
  },
  contentWrapperExpanded: {
    background: white,
    maxWidth: maxWidth,
    margin: '0 auto',
    flex: 1,
    flexWrap: 'wrap',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
  },
  topSide: {
    width: '100%',
    padding: '1.875rem .75rem 0 1.5rem',
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  wideSide: {
    width: '100%',
    padding: '1.875rem 1.5rem',
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  leftSide: {
    width: '33%',
    padding: '1.875rem 2rem',
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  rightSide: {
    background: white,
    width: '67%',
    padding: '1.875rem 2rem',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  rightSideExpanded: {
    background: white,
    width: '100%',
    padding: '1.875rem 2rem',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  infoContent: {
    flex: 1,
    margin: '2rem',
    width: 'calc(100% - 4rem)',
    maxWidth: 850,
    marginTop: '2rem',
    marginBottom: '2rem',
    position: 'relative',
  },
  infoLayoutPageTitle: {
    padding: '1.5rem',
    fontSize: '1.5rem',
  },
  infoPageContent: {
    padding: '1.5rem',
    background: white,
    borderRadius: generalBorderRadius,
  },
  tableWrapper: {
    marginBottom: '1.5rem',
    textAlign: 'center',
    overflowX: 'auto',
  },
  pageContentWrapper: {
    marginBottom: '1.5rem',
    textAlign: 'center',
  },
  contentWrapperNew: {
    maxWidth: maxWidth,
    margin: '0 auto',
    width: '100%',
    padding: '1.875rem',
  },
  widgetWrapper: {
    padding: '1.5rem 1rem',
    background: white,
    borderRadius: generalBorderRadius,
    '&:not(:last-child)': {
      marginBottom: '1.5rem',
    },
  },
  widgetTitle: {
    fontSize: '1.5rem',
    marginTop: 0,
    textAlign: 'center',
    lineHeight: 1.1,
    marginBottom: '1.5rem',
  },
  tableTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: primary,
    margin: [0, 0, '0.9375rem'],
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  errorPage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '2rem',
  },
  errorPageWhite: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '2rem',
    background: white,
  },
  errorPageHeader: {
    marginBottom: '1rem',
    padding: '1rem 2rem',
    textAlign: 'center',
  },
  errorPageDescription: {
    marginTop: 0,
    color: greyText,
  },
  authDisclaimer: {
    flex: 0,
    width: '100%',
    margin: 0,
    padding: '1rem 3rem 2rem',
    textAlign: 'left',
    background: white,
    position: 'relative',
    fontSize: '.875rem',
    '& p': {
      marginBottom: '0.5rem',
    },
    '& p:last-of-type': {
      marginBottom: '0',
    },
    '& .header': {
      fontSize: '1rem',
      margin: '0 auto .75rem',
    },
    '& .text': {
      lineHeight: 1.3,
      minHeight: 'calc(1.3 * 0.875rem)',
    },
  },
  registrationDisclaimer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 0 4rem',
    position: 'relative',
    '& .header': {
      margin: '0 auto .75rem',
    },
    '& .text': {
      fontSize: '.875rem',
      lineHeight: 1.3,
      minHeight: 'calc(1.3 * 0.875rem)',
    },
  },
  registrationDisclaimerContent: {
    maxWidth: 850,
    textAlign: 'center',
    padding: '0 1rem',
  },
  greetingWrapper: {
    fontSize: '2.25rem',
    fontWeight: 'bold',
    color: primary,
    margin: [0, 0, '1.875rem'],
  },
  simpleLayoutWrapper: {
    background: pageBackground,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  simpleInfoContent: {
    flex: 1,
    margin: '2rem',
    width: 'calc(100% - 4rem)',
    maxWidth: 850,
    marginTop: '2rem',
    marginBottom: '2rem',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  simpleLayoutContent: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    generalWrapper: {
      '&.hasFooter': {
        '& .contentSectionWrapper': {
          padding: ['1.25rem', '1.5rem'],
        },
        '& $leftSide': {
          padding: ['1.25rem', '1.5rem'],
        },
        '& $rightSide': {
          padding: 0,
        },
      },
    },
    generalWrapperExpanded: {
      '&.hasFooter': {
        '& .contentSectionWrapper': {
          padding: ['1.25rem', '1.5rem'],
        },
      },
    },
    leftSide: {
      flex: 0,
      width: '100%',
      background: white,
      padding: ['1.5rem', '2rem'],
      borderBottom: generalBorder,
    },
    rightSide: {
      flex: 1,
      width: '100%',
      padding: ['1.5rem', '2rem'],
    },
    pageTitle: {
      fontSize: '1.6125rem',
      padding: '0 0 1rem',
      margin: 0,
    },
    wideLayoutPageTitle: {
      padding: '2rem',
      fontSize: '1.875rem',
      borderBottom: 'none',
    },
    contentWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
      // padding: '0 1.5rem',
    },
    widgetWrapper: {
      padding: 0,
    },
    greetingWrapper: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      color: primary,
      background: white,
      padding: '1.5rem 2rem',
      margin: 0,
      borderBottom: generalBorder,
      textAlign: 'center',
    },
    registrationDisclaimerContent: {
      padding: '0 2rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    pageWrap: {
      padding: '0',
    },
    registrationPageWrap: {
      paddingTop: '1rem !important',
    },
    leftSide: {
      flex: 0,
      width: '100%',
      background: white,
      padding: '1.25rem',
      borderBottom: generalBorder,
    },
    rightSide: {
      flex: 1,
      width: '100%',
      padding: '1.25rem',
    },
    pageTitle: {
      fontSize: '1.5rem',
      padding: '0 0 1rem',
      margin: 0,
    },
    wideSide: {
      background: white,
      width: '100%',
      padding: ['1.25rem', '1rem'],
    },
    wideLayoutPageTitle: {
      padding: '0 0 1.5rem',
      fontSize: '1.5rem',
    },
    contentWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
      // padding: '0 1.5rem',
    },
    registrationDisclaimer: {
      marginTop: '2rem',
      '& .text': {
        fontSize: '.8125rem',
        minHeight: 'calc(1.3 * 0.8125rem)',
      },
    },
    infoContent: {
      flex: 1,
      margin: 0,
      width: '100%',
      padding: '1.5rem',
      background: white,
    },
    simpleInfoContent: {
      flex: 1,
      margin: 0,
      width: '100%',
      padding: '1.5rem',
      background: white,
    },
    infoPageContent: {
      padding: 0,
    },
    greetingWrapper: {
      fontSize: '1.5rem',
      lineHeight: 1.25,
    },
  },
  [`@media (min-width: ${SCREEN_SIZE.WIDESCREEN}px)`]: {
    leftSide: {
      padding: '1.875rem 1rem 1.875rem 2rem',
    },
    rightSide: {
      padding: '1.875rem 2rem 1.875rem 1rem',
      background: 'transparent',
    },
    pageWrap: {
      padding: '1.5rem',
      width: '100%',
    },
  },
};

export default css;
