import { SCREEN_SIZE } from '@brainysoft/lk-components';
import {
  generalBorder,
  generalBorderRadius,
  generalBorderRadiusLarge,
  generalTransition,
} from '@brainysoft/lk-custom/variables';
import { blue, primary, white } from '@brainysoft/lk-custom/colors';

export const css = {
  externalAuthWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    background: white,
    border: generalBorder,
    borderRadius: 8,
    transition: generalTransition,
    '&:hover': {
      borderColor: primary,
      cursor: 'pointer',
    },
  },
  externalAuthTitle: {
    marginRight: '1rem',
  },
  externalAuthContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  externalAuthItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gosuslugiLabel: {
    marginBottom: '.5rem',
    fontSize: '.875rem',
  },
  gosuslugiContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '.5rem',
    '& svg': {
      width: 150,
      height: 'auto',
    },
  },
  gosuslugiTitle: {
    textAlign: 'center',
  },
  externalAuthWrapperV2: {
    background: '#0066B3',
    borderRadius: generalBorderRadius,
    padding: '.75rem 1.25rem',
    color: white,
    cursor: 'pointer',
    position: 'relative',
  },
  gosuslugiContentV2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
  gosuslugiLogoV2: {
    width: 32,
    height: 32,
  },
  gosuslugiLogoV2Descriptor: {
    fontSize: '0.875rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
