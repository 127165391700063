import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { creditService, DeniedLeadDataT } from '@brainysoft/lk-components';

export const useDeniedLeadData: (creditUuid: string, enabled?: boolean) => UseQueryResult<DeniedLeadDataT> = (
  creditUuid,
  enabled = true
) => {
  return useQuery(
    ['deniedLeadData', creditUuid],
    () => {
      return creditService.deniedLeadData(creditUuid) as Promise<DeniedLeadDataT>;
    },
    {
      enabled: enabled && !!creditUuid,
      staleTime: 60000,
    }
  );
};
