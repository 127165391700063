import * as React from 'react';
import { createContainer } from 'unstated-next';
import { MutableRefObject, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { createDeferredPromise, DeferredPromiseT } from '../utils/deferred-promise';

export type CaptchaState = {
  siteKey: string | undefined;
  captchaActive: boolean;
  recaptchaRef: MutableRefObject<ReCAPTCHA>;
  recaptchaLoadPromiseRef: MutableRefObject<DeferredPromiseT>;
  recaptchaTokenPromiseRef: MutableRefObject<DeferredPromiseT>;
};

export type CaptchaMethods = {
  setSiteKey: (key: string) => void;
  setCaptchaActive: (active: boolean) => void;
};

function Reducer() {
  const [siteKey, setSiteKey] = React.useState<string | undefined>();
  const [captchaActive, setCaptchaActive] = React.useState<boolean>(false);

  const recaptchaRef = useRef<ReCAPTCHA>();
  const recaptchaLoadPromiseRef = useRef<DeferredPromiseT>(createDeferredPromise());
  const recaptchaTokenPromiseRef = useRef<DeferredPromiseT>(createDeferredPromise());

  const state = { siteKey, captchaActive, recaptchaRef, recaptchaLoadPromiseRef, recaptchaTokenPromiseRef };
  const methods = { setSiteKey, setCaptchaActive };

  return { state, methods };
}

export const CaptchaStoreContext = createContainer<
  {
    state: CaptchaState;
    methods: CaptchaMethods;
  },
  CaptchaState
>(Reducer);
