import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';

const { greyLightText, grey } = THEME_COLORS;

export const css = {
  approveWrapper: {
    position: 'relative',
    marginBottom: '2rem',
  },
  spinnerWrapper: {
    marginBottom: '1rem',
  },
  handshakeErrorWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  collapseWrapper: {},
  approveContentWrapper: {
    lineHeight: 1.2,
    fontSize: '.875rem',
    color: grey,
    display: 'block',
  },
  codeWrapper: {
    marginTop: '1rem !important',
    marginBottom: '3rem !important',
    width: '100%',
    '&.horizontal': {
      marginTop: '0 !important',
    },
  },
  inputWrapper: {
    marginTop: 'calc(1rem - 4px) !important',
    display: 'block',
  },
  captchaWrapper: {
    marginBottom: '1rem',
  },
  disclaimer: {
    color: greyLightText,
    fontSize: '.75rem',
    marginBottom: '.5rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    approveWrapper: {
      display: 'block',
    },
    inputWrapper: {
      display: 'block',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    approveContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > .field-wrapper': {
        width: '100%',
      },
    },
  },
};

export default css;
