import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { creditService, RepaymentsAvailabilityDatesT, RepaymentsAvailabilityT } from '@brainysoft/lk-components';

export const useRepaymentAvailability: (
  contractId: string | number | undefined,
  enabled?: boolean
) => UseQueryResult<RepaymentsAvailabilityT> = (contractId, enabled = true) => {
  return useQuery(
    ['RepaymentAvailable', contractId],
    () => {
      return creditService.RepaymentAvailable(contractId) as Promise<RepaymentsAvailabilityT>;
    },
    {
      enabled: !!contractId && enabled,
    }
  );
};
export const useRepaymentAvailabilityDates: (
  contractId: string | number | undefined,
  enabled?: boolean
) => UseQueryResult<RepaymentsAvailabilityDatesT> = (contractId, enabled = true) => {
  return useQuery(
    ['RepaymentAvailableDates', contractId],
    () => {
      return creditService.RepaymentAvailableDates(contractId) as Promise<RepaymentsAvailabilityDatesT>;
    },
    {
      enabled: !!contractId && enabled,
    }
  );
};
