import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { footerBackground, greyLightText, white } from '@brainysoft/lk-custom/colors';
import { generalTransition } from '@brainysoft/lk-custom/variables';
import Color from 'color';

import { css as original } from '@custom/defaults/theme/Components/Footer/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  leftSide: {
    padding: '1.875rem 1rem 1.875rem 2rem',
    '& [class^="logoImg"]': {
      maxWidth: 150,
    },
  },
  copyright: {
    fontSize: '.675rem',
    color: Color(greyLightText).lighten(0.25).rgb().string(),
    marginTop: '1rem',
    lineHeight: 1,
    '& > :not(:last-child)': {
      marginBottom: '.5rem',
    },
  },
});

export default css;
