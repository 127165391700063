import Link from 'next/link';
import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { HEADER_INVERTED } from '../../config';
import { ExternalLinkIcon } from '../Icon/ExternalLinkIcon';

interface IProps {
  href?: string;
  icon?: string;
  itemText: string;
  active?: boolean;
  offcanvas?: boolean;
  onClick?(): void;
  external?: boolean;
}

export const MainMenuItem: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('MainMenuItem');

  const { href, icon, itemText, active, offcanvas, external } = props;

  const onClick = props.onClick ? props.onClick : () => {};
  const invertedClass = HEADER_INVERTED ? 'inverted' : '';

  return (
    <React.Fragment>
      {href && !external && (
        <Link href={href}>
          <span className={classes.link}>
            {/* eslint-disable-next-line max-len */}
            <div
              className={[classes.item, invertedClass, active ? 'active' : null, offcanvas ? 'offcanvas' : ''].join(
                ' '
              )}
            >
              {icon ? <img src={icon} className={classes.icon} alt='' /> : ''}
              <span className='itemText'>{itemText}</span>
            </div>
          </span>
        </Link>
      )}
      {href && external && (
        <a className={classes.link} href={href} target='_blank' rel='noopener noreferrer'>
          {/* eslint-disable-next-line max-len */}
          <div
            className={[classes.item, invertedClass, active ? 'active' : null, offcanvas ? 'offcanvas' : ''].join(' ')}
          >
            {icon ? <img src={icon} className={classes.icon} alt='' /> : ''}
            <span className='itemText'>{itemText}</span>
            <ExternalLinkIcon color={offcanvas ? 'white' : undefined} className={classes.externalLinkIcon} />
          </div>
        </a>
      )}
      {!href && (
        <span className={classes.link} onClick={onClick}>
          <div className={[classes.item, active ? 'active' : null, offcanvas ? 'offcanvas' : ''].join(' ')}>
            {icon ? <img src={icon} className={classes.icon} alt='' /> : ''}
            <span className='itemText'>{itemText}</span>
          </div>
        </span>
      )}
    </React.Fragment>
  );
};
