import * as React from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { FormikProps } from 'formik';
import { Collapse, Panel } from '../../../../components/Collapse';
import { get } from 'lodash';
import { Col, Row } from 'antd';
import { Button } from '../../../../components/Button';
import { MaskedInput } from '../MaskedInput';
import { BS_SMS_CODE_PATTERN } from '../MaskedInput/masks';
import { OtpChallengeType } from '@brainysoft/lk-components';
import { Checkbox } from '../CheckBox';
import { Input } from '../Input';
import useDebouncedCallback from '../../../../utils/useDebouncedCallback';
import { parseTimeout, useChallenge } from '../../../../utils/useChallenge';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useTranslation } from '../../../../utils/useTranslation';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { otpService, creditService, cacheService as Storage } from '@brainysoft/lk-components';
import { Message } from '../../../../components/Message';
import { ButtonType } from 'antd/es/button';

interface IChallengeApproval {
  formik: FormikProps<any>;
  idField: string;
  approveField: string;
  otpChallengeType: OtpChallengeType;
  description?: string;
  horizontal?: boolean;
  challengeButtonSize?: SizeType;
  challengeButtonBlock?: boolean;
  proofButtonSize?: SizeType;
  proofButtonBlock?: boolean;
  proofButtonType?: ButtonType;
  proofButtonDisabled?: boolean;
  onApprove?: (result, challengeId) => Promise<any>;
  challengePayload?: Record<string, unknown>;
  phoneValidator?: (mobilePhone: string, required?: boolean) => string | undefined;
  mobilePhone: string;
  canSendChallenge?: boolean;
  onBeforeGetChallenge?: (mobilePhone: string) => Promise<boolean>;
  isPhoneCodeRequired?: boolean;
  debounceDelayMs?: number;
  shouldValidateOnChange?: boolean;
}

export const ChallengeApproval = (props: IChallengeApproval) => {
  const { t, keyExists } = useTranslation();
  const classes = useThemeStyles('Form/PhoneInputApprove');
  const {
    otpChallengeType,
    formik,
    idField,
    horizontal,
    challengeButtonSize,
    challengeButtonBlock,
    proofButtonSize,
    proofButtonBlock,
    proofButtonType,
    proofButtonDisabled,
    challengePayload,
    mobilePhone,
    approveField,
    phoneValidator,
    canSendChallenge = true,
    onBeforeGetChallenge,
    isPhoneCodeRequired = true,
    debounceDelayMs = 0,
    shouldValidateOnChange = false,
  } = props;

  const [isCodeFieldLoading, setIsCodeFieldLoading] = React.useState(false);
  const [phoneProofStatus, setPhoneProofStatus] = React.useState<string | undefined>(undefined);

  const [validPhone, setValidPhone] = React.useState<string | undefined>();
  const [isSendingRequests, setIsSendingRequests] = React.useState(false);

  const phoneValidatorFn: (mobilePhone: string) => boolean = (mobilePhone) => {
    if (typeof phoneValidator === 'function') {
      return !!phoneValidator(mobilePhone);
    }
    return !mobilePhone || mobilePhone?.length < 11;
  };

  React.useEffect(() => {
    setPhoneProofStatus(undefined);
    formik.setFieldValue(approveField, false);
    formik.setFieldValue('phoneCode', undefined);
    if (!phoneValidatorFn(mobilePhone)) {
      setValidPhone(canSendChallenge ? mobilePhone : undefined);
    }
  }, [mobilePhone, canSendChallenge]);

  const { getChallenge, isDisabled, isSending, isSent, timeout, channel, isHandshakeLoading, initHandshake, authId } =
    useChallenge(otpChallengeType, validPhone);

  const onChallenge = async () => {
    setIsSendingRequests(true);
    let allowChallenge = true;
    if (typeof onBeforeGetChallenge === 'function') allowChallenge = await onBeforeGetChallenge(validPhone);
    if (allowChallenge) await getChallenge({ ...challengePayload });
    setIsSendingRequests(false);
  };

  const onApprove = async (code) => {
    await setIsCodeFieldLoading(true);
    try {
      if (!authId) throw new Error('Undefined challengeId');
      let res, uuid, payloadData;
      switch (otpChallengeType) {
        case OtpChallengeType.OFERTA:
          if (!challengePayload?.uuid) throw new Error('Undefined uuid');
          uuid = challengePayload?.uuid;
          payloadData = { ...challengePayload };
          delete payloadData.uuid;
          res = await creditService.signOfferChallengeAnswer(uuid as string, code, payloadData);
          break;
        default:
          res = await otpService.submitChallenge(otpChallengeType, authId, code);
      }
      if (res) {
        setPhoneProofStatus('success');
        await formik.setFieldValue(approveField, true);
        await formik.setFieldValue(idField, authId);
        setIsCodeFieldLoading(false);
        await Storage.removeItem(`challenge:${otpChallengeType}:challengeId`);
        if (typeof props.onApprove === 'function') {
          await props.onApprove(res, authId);
        }
      } else {
        console.log('error', res);
        formik.setFieldError('phoneCode', 'wrongCode');
        setIsCodeFieldLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      formik.setFieldError('phoneCode', 'wrongCode');
      setIsCodeFieldLoading(false);
    }
  };

  const onApproveDebounce = useDebouncedCallback((code) => {
    onApprove(code);
  }, debounceDelayMs);

  // автоматическая отправка кода по заполнению поля
  const codeChangeHandler = (e) => {
    if (debounceDelayMs === 0) return;
    const value = e.target.value;
    if (value.length === BS_SMS_CODE_PATTERN.length) onApproveDebounce(value);
    else onApproveDebounce.cancel();
  };

  const timeLeft = parseTimeout(timeout);
  const showTimer = !!(timeLeft.minutes || timeLeft.seconds);

  if (!validPhone) return null;

  const challengeDisclaimer = keyExists(`otp:${otpChallengeType}.${channel}.description`)
    ? t(`otp:${otpChallengeType}.${channel}.description`)
    : t(`otp:allChallenges.${channel}.description`);
  const challengeButtonText = keyExists(`otp:${otpChallengeType}.${channel}.button`)
    ? t(`otp:${otpChallengeType}.${channel}.button`)
    : t(`otp:allChallenges.${channel}.button`);

  return (
    <div className={classes.approveWrapper}>
      {isHandshakeLoading && (
        <div className={classes.spinnerWrapper}>
          <LoadingSpinner size={8} margin={4} />
        </div>
      )}

      {!isHandshakeLoading && !channel && (
        <div className={classes.spinnerWrapper}>
          <Message type={'warning'}>Извините, произошла ошибка запроса. Попробуйте еще раз пожалуйста</Message>
          <Button size={'small'} onClick={initHandshake}>
            Попробовать еще раз
          </Button>
        </div>
      )}

      {!isHandshakeLoading && !!channel && !!validPhone && (
        <>
          <Checkbox name={approveField} hidden initialValue={false} />
          <Input name={idField} hidden initialValue={''} />

          <Row gutter={24}>
            <Col xs={24} sm={horizontal ? 12 : 24}>
              <div className={classes.approveContentWrapper}>
                {!!channel && <div className={classes.disclaimer}>{challengeDisclaimer}</div>}
                <div className={classes.disclaimer}>
                  {showTimer && (
                    <span>
                      {t('otp:disclaimers.approval', {
                        min: timeLeft.minutes
                          ? timeLeft.minutes < 10
                            ? `0${timeLeft.minutes}`
                            : timeLeft.minutes
                          : '00',
                        sec: timeLeft.seconds
                          ? timeLeft.seconds < 10
                            ? `0${timeLeft.seconds}`
                            : timeLeft.seconds
                          : '00',
                      })}
                    </span>
                  )}
                </div>
                <Button
                  className={classes.sendButton}
                  size={challengeButtonSize ?? 'small'}
                  disabled={isSendingRequests || isDisabled || !validPhone}
                  loading={isSending}
                  block={!!challengeButtonBlock}
                  onClick={async (e) => {
                    e.preventDefault();
                    await onChallenge();
                  }}
                >
                  {challengeButtonText}
                </Button>
              </div>
            </Col>
            <Col xs={24} sm={horizontal ? 12 : 24}>
              <Collapse
                className={`simple headerless paddingless ${classes.collapseWrapper} ${horizontal ? 'horizontal' : ''}`}
                bordered={false}
                activeKey={isSent ? 1 : undefined}
              >
                <Panel showArrow={false} header='' forceRender={true} key='1'>
                  <div className={classes.inputWrapper}>
                    <MaskedInput
                      name='phoneCode'
                      mask='pattern'
                      pattern={BS_SMS_CODE_PATTERN}
                      required={isPhoneCodeRequired}
                      loading={isCodeFieldLoading}
                      initialValue={null}
                      validateStatus={phoneProofStatus}
                      onChange={codeChangeHandler}
                      shouldValidateOnChange={shouldValidateOnChange}
                    />
                    {debounceDelayMs === 0 && (
                      <Button
                        size={proofButtonSize ?? 'middle'}
                        type={proofButtonType}
                        block={!!proofButtonBlock}
                        loading={isCodeFieldLoading}
                        disabled={isCodeFieldLoading || proofButtonDisabled === true}
                        onClick={async () => {
                          await onApprove(get(formik, 'values.phoneCode'));
                        }}
                      >
                        {t(`otp:actions.challengeApprove`)}
                      </Button>
                    )}
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
