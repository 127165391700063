import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { black } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  repaymentWidgetWrapper: {
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: '.75rem',
    '&.early-repayment': {
      background: 'rgb(235,250,215)',
      '& [class^="repaymentWidgetTitle"]': {
        // color: 'rgb(0,130,30)',
      },
      '& [class^="repaymentAmount"]': {
        // color: 'rgb(0,130,30)',
      },
    },
    '&.regular-repayment': {
      background: 'rgb(225,240,255)',
      '& [class^="repaymentWidgetTitle"]': {
        // color: 'rgb(20,60,130)',
      },
      '& [class^="repaymentAmount"]': {
        // color: 'rgb(20,60,130)',
      },
    },
    '&.arbitrary-repayment': {
      background: 'rgb(250,232,232)',
    },
    '&.prolongation': {
      background: 'rgb(255,240,230)',
      '& [class^="repaymentWidgetTitle"]': {
        // color: 'rgb(100,40,30)',
      },
      '& [class^="repaymentAmount"]': {
        // color: 'rgb(100,40,30)',
      },
    },
    '&.interest-repayment': {
      background: 'rgb(255,247,210)',
      '& [class^="repaymentWidgetTitle"]': {
        // color: 'rgb(100,40,30)',
      },
      '& [class^="repaymentAmount"]': {
        // color: 'rgb(100,40,30)',
      },
    },
  },
  repaymentWidgetTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  repaymentAmount: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  repaymentAmountTitle: {
    fontSize: '0.9125rem',
    fontWeight: 'normal',
    marginBottom: '.25rem',
    color: `${black} !important`,
  },
  repaymentInfo: {
    fontSize: '.75rem',
    marginTop: '.5rem',
    width: '100%',
    opacity: 0.7,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '.5rem',
  },
  button: {
    lineHeight: 1,
    fontSize: '.9125em !important',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    backButton: {
      width: '0.625rem',
      height: '1.5625rem',
      margin: 0,
    },
  },
};

export default css;
