export { css as Slider } from './Slider/styles';

export {
  CheckBox,
  DadataInputAntd,
  DatePicker,
  Dragger,
  FormikForm,
  FormItem,
  Input,
  InputNumber,
  MaskedInput,
  PhoneInput,
  PhoneInputApprove,
  Select,
  // Slider,
  Upload,
} from '@custom/defaults/theme/Form';
