import { VARIABLES } from '@brainysoft/lk-custom/variables';
// import {VARIABLES} from "../../variables";
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

const { primary, primaryDarken, pageBackground } = THEME_COLORS;
const { fontFamily, topBarHeight } = VARIABLES;

export const global = {
  '*,:after,:before': {
    boxSizing: 'inherit',
  },
  '*:focus': {
    outline: 0,
  },
  html: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent',
  },
  body: {
    margin: 0,
    fontFamily: fontFamily,
    background: pageBackground,
    fontSize: '16px',
    lineHeight: 1.4,
    height: '100%',
    width: '100%',
  },
  '#__next, #root': {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& header': {
      flex: `0 0 ${topBarHeight}`,
    },
    '& .content-wrap': {
      flex: 1,
    },
  },
  a: {
    color: primary,
    transition: 'all 0.3s ease',
    textDecoration: 'none',
    '&:hover, &:active, &:focus': {
      color: primaryDarken,
    },
  },
  table: {
    borderCollapse: 'unset',
  },
  'h1, h2, h3, h4, h5, h6': {
    fontWeight: 600,
  },
  'input[type=text]': {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    '#__next, #root': {
      '& header': {
        flex: 0,
      },
    },
  },
};
