import * as React from 'react';
import { useTranslation } from '../../utils/useTranslation';
import { dateParser } from '../../helpers/dateHelper';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { Currency } from '../Currency';
import { AppContext, ContractT, DisplayModeType, InterfaceModules } from '@brainysoft/lk-components';
import { Popover } from 'antd';
import { greyLightText } from '@custom/avans/colors';
import { useInterfaceModuleSetting } from '../../utils/useInterfaceModuleSetting';
import { Message } from '../Message';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import dayjs from 'dayjs';

export const ContractDetails: React.FC<ContractT> = (props) => {
  const classes = useThemeStyles('ContractInfo');
  const { t } = useTranslation();

  const { state } = AppContext.useContainer();
  const mode = state.displayMode;

  const annotations = useInterfaceModuleSetting(InterfaceModules.CONTRACT_INFO_ANNOTATIONS);

  const creationDate = props.creationDate ? dateParser(props.creationDate) : '-';
  const finishDate = props.finishDate ? dateParser(props.finishDate) : '-';
  const nextDatePay = props.nextDatePay ? dateParser(props.nextDatePay) : '-';
  const interestRate =
    props.interestForTranche && props.interestRateTypeId
      ? `${props.interestForTranche}% ${t('enums:InterestRateType.forHuman.' + props.interestRateTypeId)}`
      : '-';

  const popoverStyles = {
    fontSize: '.75rem',
    color: greyLightText,
    maxWidth: 280,
  };

  return (
    <div className={classes.infoWrap}>
      <div className={classes.block}>
        {props.creditHolidays?.isCreditHolidays && (
          <Message type={'info'} className={'text-center'}>
            <b>Уважаемый клиент!</b>
            <br />У вас оформлены кредитные каникулы с{' '}
            {dayjs(props.creditHolidays?.creditHolidaysStartDate).format('DD.MM.YYYY г.')} по{' '}
            {dayjs(props.creditHolidays?.creditHolidaysFinishDate).format('DD.MM.YYYY г.')}.
          </Message>
        )}
        <div className={classes.row}>
          <span className={classes.caption}>{`${t('contract:creditNumber')}:`}</span>
          <span className={classes.number}>{props.name ? props.name : ''}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.caption}>{`${t('contract:disbursementDate')}:`}</span>
          <span className={classes.smallText}>{creationDate}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.caption}>{`${t('contract:loanAmount')}:`}</span>
          <span className={[classes.summ, classes.blue].join(' ')}>
            <Currency amount={props.loanAmount} currency={props.currencyCode} ratio={1} />
          </span>
        </div>
        {props.finishDate && !props.isPDL && (
          <div className={classes.row}>
            <span className={classes.caption}>
              {`${t('contract:finishDate')}`}
              <span style={{ whiteSpace: 'nowrap' }}>
                :{' '}
                {!!annotations?.active && (
                  <Popover
                    placement={mode === DisplayModeType.MOBILE ? 'topLeft' : 'top'}
                    content={<span>{t('contract:annotations.finishDate')}</span>}
                    overlayInnerStyle={popoverStyles}
                  >
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Popover>
                )}
              </span>
            </span>
            <span className={classes.smallText}>{finishDate}</span>
          </div>
        )}
        <div className={classes.row}>
          <span className={classes.caption}>{`${t('contract:interestRate')}:`}</span>
          <span className={classes.smallText}>{interestRate}</span>
        </div>
        {/*{props.leftAmount ? (*/}
        {/*    <div className={classes.row}>*/}
        {/*        <span className={classes.caption}>{`${t('contract:leftRepay')}:`}</span>*/}
        {/*        <span className={classes.summ}>*/}
        {/*            <Price price={props.leftAmount} currency={props.currency} ratio={0.8} />*/}
        {/*        </span>*/}
        {/*    </div>*/}
        {/*) : null}*/}
        <div className={classes.row}>
          <span className={classes.caption}>{`${t('contract:contractStatus')}:`}</span>
          <span className={classes.smallText}>{t(props.statusCode)}</span>
        </div>
        {props.nextDatePay ? (
          <div className={classes.row}>
            <span className={`${classes.caption} ${props.isDelinquency ? classes.red : ''}`}>{`${t(
              'contract:nextPaymentDate'
            )}:`}</span>
            <span className={`${classes.smallText} ${props.isDelinquency ? classes.red : ''}`}>{nextDatePay}</span>
          </div>
        ) : null}
        {props.amountPayDetail ? (
          <React.Fragment>
            <div className={classes.row}>
              <span className={classes.caption}>{t('contract:nextPaymentAmount')}</span>
              <span className={classes.smallText}>
                <Currency amount={props.amountPayDetail?.total} currency={props.currencyCode} ratio={1} />
              </span>
            </div>
            <div className={classes.row}>
              <span className={classes.caption}>{t('contract:mainDebt')}</span>
              <span className={classes.smallText}>
                <Currency amount={props.amountPayDetail?.principal} currency={props.currencyCode} ratio={1} />
              </span>
            </div>
            <div className={classes.row}>
              <span className={classes.caption}>{t('contract:percentsAndDues')}</span>
              <span className={classes.smallText}>
                <Currency amount={props.amountPayDetail?.interest} currency={props.currencyCode} ratio={1} />
              </span>
            </div>
            <div className={classes.row}>
              <span className={classes.caption}>{t('contract:fines')}</span>
              <span className={classes.smallText}>
                <Currency amount={props.amountPayDetail?.penalty} currency={props.currencyCode} ratio={1} />
              </span>
            </div>
            {!!props.amountPayDetail?.stateDuty && (
              <div className={classes.row}>
                <span className={classes.caption}>{t('contract:stateDuty')}</span>
                <span className={classes.smallText}>
                  <Currency amount={props.amountPayDetail?.stateDuty} currency={props.currencyCode} ratio={1} />
                </span>
              </div>
            )}
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};
