import * as React from 'react';

export const Favicons: React.FC = (props) => {
  return (
    <React.Fragment>
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/favicons/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/favicons/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/favicons/favicon-16x16.png'
      />
      <link
        rel='manifest'
        href='https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/favicons/site.webmanifest'
      />
      <link
        rel='mask-icon'
        href='https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/favicons/safari-pinned-tab.svg'
        color='#5bbad5'
      />
      <link
        rel='shortcut icon'
        href='https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/favicons/favicon.ico'
      />
      <meta name='msapplication-TileColor' content='#006200' />
      <meta
        name='msapplication-config'
        content='https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/favicons/browserconfig.xml'
      />
      <meta name='theme-color' content='#ffffff' />
    </React.Fragment>
  );
};
