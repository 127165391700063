import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

const { generalBorderRadius } = VARIABLES;

export const css = {
  checkbox: {
    borderRadius: generalBorderRadius,
    fontSize: '1rem',
    padding: '0',
    height: 'auto',
    lineHeight: 1,
    display: 'inline-flex',
    alignItems: 'flex-start',
    '& .ant-checkbox': {
      top: 1,
    },
  },
  label: {
    fontSize: '.875rem',
  },
  checkboxLabel: {
    height: 'auto',
    minHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
    gap: '.25rem',
    flexWrap: 'wrap',
    lineHeight: 1.125,
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .ant-form-item': {
      marginBottom: '0 !important',
    },
    marginBottom: '.5rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    checkbox: {},
  },
};

export default css;
