import React from 'react';
import { FormikProps } from 'formik';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import useDebounce from '../../../../utils/debounce';
import { diff } from 'deep-object-diff';

interface IFormikFormProps {
  formik: FormikProps<any>;
  onUpdate?: any;
  onSubmit?: any;
  onReset?: any;
}

export const FormikForm: React.FC<React.PropsWithChildren<IFormikFormProps>> = ({ formik, onUpdate, ...props }) => {
  const classes = useThemeStyles('Form/FormikForm');
  /**
   * Callback if provided will be called when form values change
   */

  const [values, setValues] = React.useState<any>();
  const debouncedValues = useDebounce(formik.values, 10);

  React.useEffect(() => {
    if (typeof onUpdate === 'function') {
      const changed = values ? diff(values, debouncedValues) : undefined;
      setValues(formik.values);
      onUpdate(formik, changed);
    }
  }, [debouncedValues]);

  return <form {...props} className={`${classes.form}`} autoComplete={'off'} />;
};

FormikForm.whyDidYouRender = true;
