import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { QueryClient } from 'react-query/core';
import { surveysService, SurveyT, TEN_MINUTES_STALE } from '@brainysoft/lk-components';

export const useSurveys: (options?: object) => UseQueryResult<SurveyT[]> = (options = { staleTime: 0 }) => {
  return useQuery<SurveyT[]>(
    ['surveys'],
    () => {
      return surveysService.surveys();
    },
    options
  );
};

export const refreshSurveys: (queryClient: QueryClient) => void = (queryClient) => {
  return queryClient.invalidateQueries(['surveys']);
};
