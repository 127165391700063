import React from 'react';
import { WDYR } from './src/config';

if (process.env.NODE_ENV === 'development' && WDYR && true) {
  if (typeof window !== 'undefined') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: false,
      collapseGroups: true,
    });
  }
}
