export const css = {
  listOfAvialableRegion: {
    '& h3': {
      fontSize: '0.85rem',
      fontWeight: 'normal',
    },
    '& li': {
      fontSize: '0.85rem',
    },
    '& p': {
      fontSize: '0.85rem',
    },
  },
};

export default css;
