import documentsRu from './translations/ru/documents.json';
import printFormsRu from './translations/ru/printForms.json';
import widgetsRu from './translations/ru/widgets.json';
import headerRu from './translations/ru/header.json';
import otpRu from './translations/ru/otp.json';
import prolongationRu from './translations/ru/prolongation.json';
import errorsRu from './translations/ru/errors.json';
import denyRu from './translations/ru/deny.json';

export const translations = {
  ru: {
    documents: documentsRu,
    printForms: printFormsRu,
    widgets: widgetsRu,
    header: headerRu,
    otp: otpRu,
    prolongation: prolongationRu,
    errors: errorsRu,
    deny: denyRu,
  },
};
