import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import Color from 'color';

const { fontFamily, fontSizeLarge, generalBorder, generalBorderRadius, generalTransition, inputBorderColor } =
  VARIABLES;

const { greyLightText } = THEME_COLORS;

export const css = {
  input: {
    '& input, & textarea': {
      width: '100%',
      borderRadius: generalBorderRadius,
      fontSize: fontSizeLarge,
      padding: '.75rem 1.75rem .75rem 1.25rem',
      height: 'auto',
      lineHeight: '24px !important',
      fontFamily: fontFamily,
      border: generalBorder,
      '&::placeholder': {
        color: greyLightText,
      },
      transition: generalTransition,
      '&:hover': {
        borderColor: inputBorderColor,
      },
      '&:focus': {
        outline: 0,
        borderColor: inputBorderColor,
        boxShadow: `0 0 0 2px ${Color(inputBorderColor).fade(0.8).rgb().string()} !important`,
      },
    },
    '& .ant-input-textarea-suffix': {
      position: 'absolute',
      right: '.75rem',
      top: '.875rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    select: {},
  },
};

export default css;
