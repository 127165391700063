import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import { buildQuery } from '../../utils';
import { OtpChallengeType, ChallengeChannelType } from '../../enums';

export type OtpChallenge = {
  challenge: {
    id: number;
    type: string;
    status: string;
    channel: string;
  };
  available: {
    type: string;
    channel: string;
    availableIn: number;
    captcha?: {
      type: CaptchaType;
    };
    site_key?: string;
  };
};

type CaptchaType = 'recaptchav2';

export type HandshakeResponse = {
  type: OtpChallengeType;
  reason: string;
  channel: ChallengeChannelType;
  availableIn: number;
  captcha: {
    type: CaptchaType;
  } | null;
  site_key?: string;
};

export type CheckPhoneResponse = {
  status: boolean;
};

class Otp extends Entity {
  public async handshake(name: OtpChallengeType, mobilePhone: string): Promise<HandshakeResponse> {
    this.storeEntity = 'otp';
    const result = await this.fetch('', `/${name}/handshake?${buildQuery({ mobilePhone })}`);
    return result as HandshakeResponse;
  }

  public async getChallenge(name: OtpChallengeType, payload?: any): Promise<OtpChallenge> {
    this.storeEntity = 'otp';
    const result = await this.post(payload, `/${name}/challenge`);
    return result as OtpChallenge;
  }

  public async getChallengeWithConsumer(name: OtpChallengeType, payload?: any): Promise<OtpChallenge> {
    this.storeEntity = 'otp';
    const result = await this.post(payload, `/${name}/challenge/withConsumer`);
    return result as OtpChallenge;
  }

  public async submitChallenge(name: OtpChallengeType, id: number, code: string) {
    this.storeEntity = 'otp';
    const result = await this.post({ id, code }, `/${name}/challenge_answer`);
    return result;
  }

  public async checkPhone(mobilePhone: string): Promise<CheckPhoneResponse> {
    this.storeEntity = 'otp';
    const result = await this.post({ mobilePhone }, `/checkPhone`);
    return result;
  }
}

export const otpService = new Otp(fetch, API, cache, config);
