import { SCREEN_SIZE } from '@brainysoft/lk-components';
import {
  generalBorder,
  generalBorderRadius,
  generalBorderRadiusLarge,
  generalTransition,
} from '@brainysoft/lk-custom/variables';
import Color from 'color';
import { blue, greyLight, greyText, pageBackground, white, primary, greyLightText } from '@brainysoft/lk-custom/colors';

export const css = {
  repaymentPageWrapper: {
    fontSize: '.9125rem',
  },
  repaymentContentWrapper: {
    minHeight: 100,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: '0 1.5rem',
    background: white,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  header: {
    lineHeight: 1.2,
    fontSize: '1.125rem',
    marginTop: 0,
    textAlign: 'center',
  },
  descriptor: {
    marginBottom: 0,
  },
  creditInfo: {
    maxWidth: 400,
    padding: '1.5rem',
    margin: '1.5rem',
    textAlign: 'center',
    border: generalBorder,
    borderRadius: generalBorderRadiusLarge,
    width: '100%',
    background: Color(pageBackground).lighten(0.035).rgb().string(),
  },
  creditInfoHeader: {
    marginTop: 0,
    marginBottom: '1rem',
  },
  creditInfoDetails: {
    fontSize: '1.25rem',
  },
  creditInfoAmountWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '.5rem',
  },
  creditInfoPeriodWrapper: {
    fontSize: '0.9125rem',
  },
  creditInfoPeriod: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  cardsWrapper: {
    margin: '1rem auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& > [class^="card"]': {
      margin: '0 .5rem 1rem',
    },
  },
  activeCreditButtonWrapper: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    '& .ant-btn:not(:last-child)': {
      marginRight: '.75rem',
    },
  },
  activeCreditPlaceholderButtonWrapper: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  activeCreditPlaceholderTitle: {
    fontSize: '.875rem',
    textAlign: 'center',
    color: greyText,
    marginBottom: '1rem',
  },
  passportImgWrapper: {
    position: 'relative',
    padding: '0 1rem',
    '& img': {
      width: '100%',
    },
  },
  statusIcon: {
    position: 'absolute',
    top: '0',
    right: '1rem',
    transform: 'translate(50%,-40%)',
    borderRadius: '500px',
  },
  interactionSubmitWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  homeButton: {
    marginTop: '1rem',
  },
  prolongationTermsWrapper: {
    width: '100%',
    marginTop: '1rem',
  },
  prolongationTermWrapper: {
    background: Color(greyLight).lighten(0.12).rgb().string(),
    borderRadius: generalBorderRadiusLarge,
    border: generalBorder,
    padding: '1rem',
    textAlign: 'center',
    marginBottom: '1rem',
    transition: generalTransition,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 20px rgba(0,0,0,0.075)',
    },
    '&.selected': {
      borderColor: blue,
      background: Color(blue).mix(Color(white), 0.95).rgb().string(),
    },
  },
  prolongationTerms: {
    marginBottom: '.75rem',
    fontWeight: 'bold',
  },
  prolongationTermsPeriod: {
    fontSize: '1.5rem !important',
  },
  price: {
    fontSize: '1.5rem !important',
  },
  prolongationNewPeriod: {
    color: greyText,
    fontSize: '0.875rem',
  },
  amountWrapper: {
    width: '50%',
  },
  phoneMessage: {
    fontSize: '.875rem',
    lineHeight: 1.3,
    '& .ant-btn': {
      marginTop: '1rem',
      fontSize: '.875rem',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  amount: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: primary,
  },
  commission: {
    color: greyLightText,
    fontSize: '.875rem',
    marginTop: '1rem',
    paddingTop: '1.25rem',
    position: 'relative',
    textAlign: 'left',
    lineHeight: 1.2,
    '& > div:not(:last-child)': {
      marginBottom: '.25rem',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      right: 0,
      height: '8px',
      background: `radial-gradient(closest-side, ${greyLight}, ${greyLight} 50%, transparent 50%)`,
      backgroundSize: '8px 8px',
    },
  },
  invoice: {
    padding: '1rem',
    border: `1px solid ${greyLight}`,
    boxShadow: '0 10px 20px rgba(0,0,0,0.05)',
    borderRadius: generalBorderRadius,
  },
  amountContent: {
    position: 'relative',
    minHeight: 150,
    minWidth: 180,
  },
  iframeWrapper: {
    minHeight: '550px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > iframe': {
      border: 'none',
      width: '100%',
      minHeight: '550px',
    },
  },
  disclaimer: {
    margin: '1rem 0',
    color: '#82859B',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    repaymentPageWrapper: {
      fontSize: '.875rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    amountWrapper: {
      width: '100%',
    },
    activeCreditButtonWrapper: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& .ant-btn': {
        marginBottom: '.75rem',
      },
    },
    amount: {
      fontSize: '2rem',
    },
    amountContent: {
      minHeight: 120,
    },
  },
};

export default css;
