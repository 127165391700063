import cache, { cacheService as Storage } from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { IProcessService, ProcessSaveDTO, ProcessSetStepDTO } from '../../interfaces';
import { ProcessType } from '@./shared';
import { buildQuery } from '../../utils';

class ProcessService extends Entity implements IProcessService {
  public async init(name, data = {}) {
    this.storeEntity = 'process';
    const result = await this.post(data, `/${name}/init`);
    return result;
  }

  public async meta(name) {
    this.storeEntity = 'process';
    const result = await this.fetch('', `/${name}/meta`);
    return result;
  }

  public async getOne(name, uuid, sessionId) {
    this.storeEntity = 'process';
    this.processUuid = uuid;
    const result = await this.fetch('', `/${name}/${uuid}/${sessionId}`);
    return result;
  }

  public async goForward(name, data: ProcessSaveDTO, currentStep?: string) {
    this.storeEntity = 'process';
    this.processUuid = data.uuid;
    const query = currentStep ? `?${buildQuery({ step: currentStep })}` : '';
    const result = await this.post(data, `/${name}/${data.uuid}/${data.session}/go-forward${query}`);
    return result;
  }

  public async goBack(name, data: ProcessSetStepDTO, currentStep?: string) {
    this.storeEntity = 'process';
    this.processUuid = data.uuid;
    const query = currentStep ? `?${buildQuery({ step: currentStep })}` : '';
    const result = await this.post(data, `/${name}/${data.uuid}/${data.session}/go-back${query}`);
    return result;
  }

  public async update(name, uuid, sessionId, data) {
    this.storeEntity = 'process';
    this.processUuid = uuid;
    const result = await this.post(data, `/${name}/${uuid}/${sessionId}/update`);
    return result;
  }

  public async checkData(data, processUuid?: string) {
    this.storeEntity = 'process';
    this.processUuid = processUuid;
    const result = await this.post(data, '/check/data');
    return result;
  }

  public async saveProcessFile(name, uuid, session, data) {
    this.storeEntity = 'process';
    this.processUuid = uuid;
    const result = await this.post(data, `/${name}/${uuid}/${session}/file`, true);
    return result;
  }

  public async loadProcessFile(name, uuid, session, code) {
    this.storeEntity = 'process';
    this.processUuid = uuid;
    const result = await this.download('', `/${name}/${uuid}/${session}/file/${code}`);
    return result;
  }

  public async getProcessFileMeta(name, uuid, session, code) {
    this.storeEntity = 'process';
    this.processUuid = uuid;
    const result = await this.fetch('', `/${name}/${uuid}/${session}/file/${code}/meta`);
    return result;
  }

  public async isLocked(name: string) {
    this.storeEntity = 'process';
    const result = await this.fetch('', `/${name}/is-locked`);
    return result;
  }

  public async checkEmail(email: string, processUuid?: string) {
    this.storeEntity = 'process';
    this.processUuid = processUuid;
    const result = await this.post({ email }, '/check/email');
    return result;
  }

  public async getProcessFilesData(processName: ProcessType, uuid: string, session: string) {
    this.storeEntity = 'process';
    const result = await this.fetch('', `/${processName}/${uuid}/${session}/files-data`);
    return result;
  }
}

export const processService = new ProcessService(fetch, API, cache, config);
