import { SetStorageRecaptchaKey } from './useCaptcha';
import { createDeferredPromise } from './deferred-promise';
import { CaptchaStoreContext } from '../ctx/captcha-context';

type CaptchaResponseT = {
  captcha_required: boolean;
  captcha_site_key: string;
};

type UseInterceptorT = {
  interceptor: <ResponseT = Record<string, unknown> | undefined>(
    method: Promise<ResponseT>
  ) => (...args: any[]) => Promise<CaptchaResponseT | ResponseT>;
};

export const useInvisibleCaptcha = (): UseInterceptorT => {
  const { state, methods } = CaptchaStoreContext.useContainer();
  const { recaptchaRef, recaptchaTokenPromiseRef, recaptchaLoadPromiseRef } = state;

  const interceptor =
    (method) =>
    async (...args) => {
      const response = await method(...args);

      if (response && response?.captcha_required && response?.captcha_site_key) {
        methods.setSiteKey(response?.captcha_site_key);
        methods.setCaptchaActive(true);
        await recaptchaLoadPromiseRef.current.promise;

        await recaptchaRef.current.execute();
        const token = await recaptchaTokenPromiseRef.current.promise;
        await SetStorageRecaptchaKey(String(token));

        await recaptchaRef.current.reset();
        recaptchaLoadPromiseRef.current = createDeferredPromise();
        recaptchaTokenPromiseRef.current = createDeferredPromise();

        const res = await method(...args);

        methods.setCaptchaActive(false);

        return res;
      } else {
        return response;
      }
    };

  return { interceptor };
};
