import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useTranslation } from '../../utils/useTranslation';
import * as config from '@brainysoft/lk-custom/config';
import { useLegalEntityInfo } from '../../queries/useLegalEntityInfo';
import { phoneRaw, phoneParsed } from '../../helpers';
import { PhoneObjectT } from '@brainysoft/lk-components';
import { HEADER_INVERTED } from '@brainysoft/lk-custom/config';

interface HeaderPhoneProps {
  inside?: boolean;
  inverted?: boolean;
  auth?: boolean;
}

export const HeaderPhone: React.FC<HeaderPhoneProps> = (props) => {
  const classes = useThemeStyles('HeaderPhone');
  const { tAsArray } = useTranslation();

  const { inside, auth } = props;

  const legalEntityQuery = useLegalEntityInfo();
  let telephones: PhoneObjectT[] = [
    {
      formatted: phoneParsed(legalEntityQuery?.data?.telephone),
      raw: legalEntityQuery.data?.telephone ?? '',
    },
  ];

  const description = tAsArray('header:phoneDescription').map((text, index) => <div key={index}>{text}</div>);

  const configPhones = config['PHONES'] ?? [];

  if (configPhones.length) {
    telephones = configPhones.map((phone) => {
      return {
        formatted: phone,
        raw: phoneRaw(phone),
      };
    });
  }

  const insideClass = inside ? 'inside' : '';
  const invertedClass = HEADER_INVERTED ? 'inverted' : '';
  const authClass = auth ? 'auth' : '';

  return (
    <div className={`${classes.phoneWrap} ${insideClass}`}>
      <div className={classes.phoneBlockWrapper}>
        {telephones.length &&
          telephones.map((telephone, index) => {
            return (
              <React.Fragment key={index}>
                <a className={`${classes.phoneNumber} ${invertedClass} ${authClass}`} href={`tel:+${telephone.raw}`}>
                  {telephone.formatted ?? ''}
                </a>
              </React.Fragment>
            );
          })}
      </div>
      <span className={`${classes.phoneDetail} ${invertedClass} ${authClass}`}>{description}</span>
    </div>
  );
};
