import { SCREEN_SIZE } from '@brainysoft/lk-components';

export const css = {
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  nonIframeContent: {
    flex: 0,
    width: '100%',
    padding: '1.5rem',
    textAlign: 'center',
  },
  header: {
    marginBottom: '.5rem',
  },
  disclaimer: {
    fontSize: '.875rem',
    '& > *': {
      marginBottom: '0.25rem',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  iframe: {
    flex: 1,
    border: 'none',
    width: '100%',
    height: '100%',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    nonIframeContent: {
      padding: '1rem',
    },
  },
};

export default css;
