import { black } from '@brainysoft/lk-custom/colors';

import { css as original } from '@custom/defaults/theme/Components/Layout/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  greetingWrapper: {
    color: black,
  },
});

export default css;
