import { FC } from 'react';
import { FooterT } from '@brainysoft/lk-components';
import { FooterView } from '../../../components/Footer/FooterView';

const footer: FooterT = {
  contacts: [
    [
      {
        label: 'Телефон',
        text: '8 800 300 57 99',
        url: 'tel:88003005799',
        target: '_blank',
      },
      {
        label: 'Электронная почта',
        text: 'info@avans-online.ru',
        url: 'mailto:info@avans-online.ru',
        target: '_blank',
      },
    ],
    [
      {
        label: 'Адрес',
        text: 'г. Ростов-на-Дону, ул. Зорге 52',
      },
      {
        label: 'График работы офиса:',
        text: ['Будни: с 9:00 до 20:00', 'Выходные и праздники: с 10:00 до 19:00'],
      },
    ],
  ],
  disclaimer: [
    'ООО МКК «Аванс Онлайн». Свидетельство о внесении сведений о юридическом лице в государственный реестр микрофинансовых организаций за № 2003475009495 от 22.01.2020 г.',
    {
      text: 'Официальная документация',
      url: 'https://avans-online.ru/dokumenty/',
      target: '_blank',
    },
  ],
  copyright: '© ООО МКК «Аванс Онлайн»',
};

export const Footer: FC = () => {
  return <FooterView footer={footer} />;
};
