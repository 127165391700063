export enum OtpChallengeType {
  ENTRANCE = 'entrance',
  AUTH = 'auth',
  REGISTRATION = 'registration',
  OFERTA = 'oferta',
  DOCUMENTS_SIGN = 'documentsSign',
  DOCUMENTS_SIGN_UPDATE = 'documentsSignUpdate',
  PROLONGATION = 'prolongation',
  LOAN_APPLICATION = 'loanApplication',
  SIMILAR_DATA_AUTH = 'similarDataAuth',
  EARLY_REPAYMENT = 'earlyRepayment',
}

export const showPhoneField: (code: OtpChallengeType) => boolean = (code) => {
  switch (code) {
    case OtpChallengeType.AUTH:
    case OtpChallengeType.REGISTRATION:
      return true;
  }
  return false;
};
