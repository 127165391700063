import * as React from 'react';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { Col, Divider, Row } from 'antd';
import { Currency } from '../../Currency';
import { isEmpty } from 'lodash';
import { useProlongationSettings } from '../../../queries/useProlongationSettings';
import { Message } from '../../Message';
import { useLegalEntityInfo } from '../../../queries/useLegalEntityInfo';
import { phoneParsed } from '../../../helpers';
import { ContractT, SettingsType } from '@brainysoft/lk-components';
import { LoadingSpinner } from '../../LoadingSpinner';
import { useTranslation } from '../../../utils/useTranslation';
import { IStatefulProcessRenderer } from '../../StatefulProcess/AStatefulProcess';
import Decimal from 'decimal.js-light';
import { StatefulProcessActions } from '../../StatefulProcess/StatefulProcessActions';
import { useSetting } from '../../../utils/useSetting';
import cls from 'classnames';
import dayjs from 'dayjs';

export const ProlongationChooseAmount: React.FC<IStatefulProcessRenderer> = (props) => {
  const { t } = useTranslation();
  const classes = useThemeStyles('RepaymentPage');

  const { contract, state, goForward, isMutating } = props;

  const [selected, setSelected] = React.useState<any>({});
  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
  const prolongationQuery = useProlongationSettings(contract.id);
  const marketingActions = useSetting(SettingsType.MARKETING_ACTIONS);

  const legalEntityQuery = useLegalEntityInfo();
  const telephone = {
    formatted: phoneParsed(legalEntityQuery?.data?.telephone),
    raw: legalEntityQuery.data?.telephone ?? '',
  };

  const clickNext = () => {
    goForward({
      period: selected.period,
      amount: selected.amount ? new Decimal(selected.amount).toDecimalPlaces(2).toNumber() : undefined,
      // prolongationRepaymentDate: selected.prolongationRepaymentDate,
    });
  };

  let amount,
    showPeriods = false,
    hideAmount;

  prolongationQuery.data?.forEach((item, index) => {
    hideAmount = index === 0 ? true : hideAmount && item.amount === amount;
    amount = item.amount;
    showPeriods = showPeriods || Boolean(item.amount);
  });

  if (marketingActions?.prolongation?.active) hideAmount = false;

  return (
    <React.Fragment>
      <div className={cls(classes.repaymentContentWrapper, classes.wrapper)}>
        <h2 className={classes.header}>{t('repayments:prolongation.changePeriod')}</h2>

        {(prolongationQuery.isLoading || prolongationQuery.isFetching) && (
          <div className={classes.loaderWrapper}>
            <LoadingSpinner size={8} margin={4} />
          </div>
        )}

        {!(prolongationQuery.isLoading || prolongationQuery.isFetching) && showPeriods && (
          <React.Fragment>
            <Row gutter={24} justify='center' className={classes.prolongationTermsWrapper}>
              {prolongationQuery.data?.map((prolongation, index) => (
                <Col xs={24} md={6} key={index}>
                  <ProlongationTerm
                    isSelected={selected?.index === index}
                    index={index}
                    contract={contract}
                    period={prolongation.days}
                    amount={prolongation.amount}
                    hideAmount={hideAmount}
                    setSelected={setSelected}
                  />
                </Col>
              ))}
            </Row>
            {hideAmount && (
              <div>
                {t('repayments:prolongation.disclaimer')}{' '}
                <b>
                  <Currency className={classes.price} amount={amount} ratio={0.85} />
                </b>
              </div>
            )}
            {marketingActions?.prolongation?.active && contract?.isDelinquency && (
              <Row gutter={24} justify='center' className={classes.prolongationTermsWrapper}>
                <Col xs={24} md={prolongationQuery.data?.length ? prolongationQuery.data?.length * 6 : 18}>
                  <Message type={'info'}>
                    {t('repayments:prolongation.fixedAmountSize')}{' '}
                    <Currency amount={marketingActions?.prolongation?.amount} ratio={0.8} />{' '}
                    {t('repayments:prolongation.marketingInfo', {
                      finishDate: marketingActions?.prolongation?.finishDate,
                    })}
                    .
                  </Message>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}

        {!(prolongationQuery.isLoading || prolongationQuery.isFetching) && !showPeriods && (
          <Message type={'error'}>
            {t('repayments:prolongation.message.error')} <br /> {t('repayments:prolongation.message.errorAdvice')}{' '}
            <a href={`tel:${telephone.raw}`}>{telephone.formatted}</a>
          </Message>
        )}
      </div>

      <Divider />

      <StatefulProcessActions
        showForward={state.transitions?.forward}
        showBack={state.transitions?.back}
        onClickForward={clickNext}
        textForward={t('repayments:prolongation.forward')}
        isForwardDisabled={isEmpty(selected) || buttonsDisabled}
        isMutating={isMutating}
      />
    </React.Fragment>
  );
};

interface IProlongationTerm {
  index: number;
  contract: ContractT;
  period: number;
  amount: number;
  hideAmount?: boolean;
  setSelected: (arg: { index: number; period: number; amount: number; prolongationRepaymentDate: string }) => void;
  isSelected: boolean;
}

const ProlongationTerm: React.FC<IProlongationTerm> = (props) => {
  const classes = useThemeStyles('RepaymentPage');
  const { t } = useTranslation();

  const { index, contract, period, amount, hideAmount, isSelected, setSelected } = props;

  const newPaymentDate = contract.earlyProlongationFromCurrentDate
    ? dayjs().add(period, 'days')
    : dayjs(contract?.nextDatePay).add(period, 'days');

  const onClick = () => {
    setSelected({
      index: index,
      period: period,
      prolongationRepaymentDate: newPaymentDate.format('YYYY-MM-DD'),
      amount: amount,
    });
  };

  return (
    <div className={`${classes.prolongationTermWrapper} ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <div className={classes.prolongationTerms}>
        <span className={classes.prolongationTermsPeriod}>{period}</span>
        {t('repayments:prolongation.prolongationTermsPeriod.days')} <br />
        {!hideAmount && (
          <React.Fragment>
            {t('repayments:prolongation.prolongationTermsPeriod.per')}
            <Currency className={classes.price} amount={amount} ratio={0.85} />
          </React.Fragment>
        )}
      </div>
      <div className={classes.prolongationNewPeriod}>
        {t('repayments:prolongation.prolongationNewPeriod')}: {newPaymentDate.format('DD.MM.YYYY')}
      </div>
    </div>
  );
};
