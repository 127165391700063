import * as React from 'react';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { cacheService as Storage, ContractT, RepaymentsAvailabilityT, RepaymentType } from '@brainysoft/lk-components';
import { Button } from '../../Button';
import { useTimer } from '../../../utils/useTimer';
import { useTranslation } from '../../../utils/useTranslation';
import { useRouter } from 'next/router';
import { useRepaymentAvailability } from '../../../queries/useRepaymentAvalability';
import { Skeleton } from 'antd';

interface IProps {
  contract: ContractT;
}

export const ProlongationWidget: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('RepaymentWidget');
  const { t } = useTranslation();
  const router = useRouter();
  const { isExpired } = useTimer('repayment');

  const { contract } = props;

  const repaymentsAvailabilityQuery = useRepaymentAvailability(contract?.id);
  const repaymentsAvailability = repaymentsAvailabilityQuery.data || ({} as RepaymentsAvailabilityT);

  const repaymentType = RepaymentType.PROLONGATION;

  const onClick = async () => {
    await Storage.setItem(`repayment:${repaymentType}:contractId`, contract?.id);
    await router.push(`/repayment/${repaymentType}/init`);
  };

  if (repaymentsAvailabilityQuery.isLoading)
    return (
      <div className={`${classes.repaymentWidgetWrapper} ${repaymentType}`}>
        <Skeleton className={classes.skeleton} active={true} title={false} paragraph={{ rows: 2, width: '80%' }} />
      </div>
    );
  if (!repaymentsAvailability[repaymentType]) return <></>;

  return (
    <div className={`${classes.repaymentWidgetWrapper} ${repaymentType}`}>
      <div className={classes.repaymentWidgetTitle}>{t(`widgets:repaymentWidget.${repaymentType}.title`)}</div>
      {!isExpired && <div className={classes.repaymentInfo}>{t('widgets:repaymentWidget.repaymentInfo')}</div>}
      {isExpired && (
        <>
          <div className={classes.repaymentInfo}>{t(`widgets:repaymentWidget.${repaymentType}.description`)}</div>
          <div className={classes.buttonWrapper}>
            <Button
              type={'primary'}
              shape='round'
              size='small'
              color={'white'}
              className={classes.button}
              disabled={!isExpired}
              onClick={onClick}
            >
              {t(`widgets:repaymentWidget.${repaymentType}.makePayment`)}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
