import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { black, green, greyLight, primary, red } from '@brainysoft/lk-custom/colors';
import { generalTransition } from '@brainysoft/lk-custom/variables';

export const css = {
  header: {
    fontSize: '1.25rem',
    marginTop: '1rem',
    marginBottom: '2rem',
    color: black,
  },
  processWrapper: {
    margin: '0 auto',
    padding: '2rem 2rem 3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  processContent: {
    flex: '1 1 100%',
  },
  processActions: {
    marginTop: '2rem',
    flex: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  processSteps: {
    marginTop: '0 !important',
  },
  formWrapper: {
    margin: 0,
  },
  cardsWrapper: {
    width: '90%',
    margin: '0 auto 1rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& > [class^="card"]': {
      margin: '0 .5rem 1rem',
    },
  },
  methodsWrapper: {
    width: '100%',
    margin: '0 auto 1rem',
    display: 'flex',
    flexWrap: 'wrap',
    '& > [class^="money"]': {
      margin: '0 .5rem 1rem',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  success: {
    textAlign: 'center',
    lineHeight: 1.4,
  },
  cardApproveWrapper: {
    fontSize: '0.875rem',
    marginBottom: '1rem',
  },
  document: {
    border: `1px solid ${greyLight}`,
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.5rem',
    cursor: 'pointer',
    transition: generalTransition,
    '&>span>i': {
      marginRight: '1rem',
    },
    '&:hover': {
      color: primary,
      borderColor: primary,
    },
  },
  documentsInfo: {
    fontSize: '.875rem',
    lineHeight: 1.3,
  },
  moneyMethodWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  phoneMessage: {
    marginTop: '1rem',
    marginBottom: '1rem',
    fontSize: '.875rem',
    // '& .ant-btn': {
    //     fontSize: '.875rem',
    //     marginTop: 0,
    // },
  },
  codeField: {
    marginTop: '1.5rem',
  },
  smevResultWrapper: {
    textAlign: 'center',
    '& .headerSuccess': {
      color: green,
    },
  },
  smevResultInfo: {
    marginTop: '2rem',
  },
  skeleton: {
    '& .ant-skeleton-content .ant-skeleton-paragraph > li': {
      height: '1.25rem',
      marginBottom: '1.5rem',
    },
  },
  completedTextWrapper: {
    fontSize: '1rem',
    marginBottom: '2rem',
  },
  label: {
    fontSize: '.875rem',
    marginBottom: '.5rem',
  },
  iframeWrapper: {
    position: 'relative',
    minHeight: '550px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > iframe': {
      border: 'none',
      width: '100%',
      minHeight: '550px',
    },
  },
  ocrResultContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem auto',
    maxWidth: '80%',
  },
  ocrResultWrapper: {
    width: '100%',
  },
  ocrResult: {
    display: 'flex',
    '& [class*="resultIconWrapper"]': {
      margin: '0 .75rem .75rem 0',
    },
  },
  ocrResultText: {
    display: 'flex',
    flexDirection: 'column',
  },
  ocrResultError: {
    fontSize: '.75rem',
    color: red,
  },
  cardDisclaimer: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '0.85rem',
    padding: '0 2.5rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    processWrapper: {
      margin: 0,
      padding: '2rem',
      width: '100%',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    header: {
      fontSize: '1.25rem',
    },
    processWrapper: {
      margin: 0,
      padding: 0,
      width: '100%',
    },
    completedTextWrapper: {
      fontSize: '.875rem',
    },
    cardsWrapper: {
      width: '100%',
      justifyContent: 'center',
      margin: 0,
    },
    moneyMethodWrapper: {
      justifyContent: 'center',
    },
    ocrResultContainer: {
      maxWidth: '100%',
    },
  },
};

export default css;
