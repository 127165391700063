import * as React from 'react';
import { ResultIcon } from '../../ResultIcon';
import { Button } from '../../Button';
import { IActiveCreditStatus } from '../ActiveCreditSelector';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { ActiveCreditStatusLoading } from '../ActiveCreditStatusLoading';
import { CreditStatusType, DeniedLeadDataT, ResultType } from '@brainysoft/lk-components';
import { useTranslation } from '../../../utils/useTranslation';
import { useGoogleAnalytics } from 'apps/lk-next/src/integrations/useGoogleAnalytics';
import { useYandexMetrika } from 'apps/lk-next/src/integrations/useYandexMetrika';
import { useDeniedLeadData } from '../../../queries/useDeniedLeadData';
import dayjs from 'dayjs';

export const Denied: React.FC<IActiveCreditStatus> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { credit } = props;
  const { t } = useTranslation();

  const deniedLeadDataQuery = useDeniedLeadData(credit?.uuid, credit?.status === CreditStatusType.DENIED);
  const deniedData = deniedLeadDataQuery.data || ({} as DeniedLeadDataT);
  const isDeniedLoading = deniedLeadDataQuery.isLoading;

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const iconSize = 60;

  const text = deniedData.expirationDate
    ? t('deny:headerWithDate', { date: dayjs(deniedData.expirationDate).format('DD.MM.YYYY г.') })
    : t('deny:header');

  const partnersText = deniedData.link ? t('deny:partnersText') : '';

  return (
    <React.Fragment>
      {isDeniedLoading && <ActiveCreditStatusLoading />}
      {!isDeniedLoading && (
        <div className={`${classes.wrapper} inWidget`}>
          <h2 className={classes.deniedHeader}>{text}</h2>
          <ResultIcon result={ResultType.FAIL} size={iconSize} />
          {!deniedData?.link && (
            <div className={`${classes.deniedDescription} mt1`}>{t('active:statuses.denied.tryAgainLater')}.</div>
          )}
          {deniedData?.link && (
            <React.Fragment>
              <div className={`${classes.deniedDescription} mt1`}>{partnersText}</div>
              <Button
                size={'large'}
                type={'primary'}
                className={classes.homeButton}
                onClick={() => {
                  if (deniedData?.link) {
                    ga.send('monetization');
                    ym.send('monetization');
                    window.open(deniedData?.link, '_blank');
                  }
                }}
              >
                {t('active:statuses.denied.go')}
              </Button>
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
