// customer monedo

export const NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';

export const TENANT_DOMAIN = process.env.NEXT_PUBLIC_TENANT_DOMAIN;

export const SMS_SEND_DELAY = 30000;
export const EMAIL_SEND_DELAY = 30000;
export const STATUS_CHECK_INTERVAL = 20000;
export const REFRESH_DATA_INTERVAL = 60000;

export const DADATA_TOKEN = 'c81438ae51a4c5629ef2f4674660a6798e04854e';
export const LOCALE = 'ru';
export const PHONE_MASK = '8';
export const CURRENCY = 'RUR';

export const HEADER_INVERTED = true;
export const MAX_DOWNLOAD_FILE_SIZE = 10; // Megabytes
export const MIN_FIAS_LVL = 7;

export const OPTIONS = {
  validatePhone: (value: string) => {
    const prefix = value.slice(0, 2);
    return prefix === '79';
  },
  validatePhoneErrorMessage: 'Введите мобильный номер телефона в формате +7(9xx)xxx-xx-xx.',
  blinkTitle: true,
};

export const WDYR = false;

export const INTEGRATIONS = {
  yandexMetrika: {
    id: 29118470,
    goals: {
      registration: { event: 'registration' },
      photo_upload: { event: 'photo_upload' },
      consent_sign_new: { event: 'consent_sign_new' },
      login: { event: 'login' },
      consent_sign_repeat: { event: 'consent_sign_repeat' },
      monetization: { event: 'monetization' },
      agreement_sign: { event: 'agreement_sign' },
      attach_card: { event: 'attach_card' },
      esia: { event: 'esia' },
    },
  },
  googleAnalytics: {
    id: 'UA-40703727-1',
    goals: {
      registration: { event: 'registration', event_category: 'new' },
      photo_upload: { event: 'photo_upload', event_category: 'new' },
      consent_sign_new: { event: 'consent_sign', event_category: 'new' },
      login: { event: 'login', event_category: 'repeat' },
      consent_sign_repeat: { event: 'consent_sign', event_category: 'repeat' },
      monetization: { event: 'monetization', event_category: 'general' },
      agreement_sign: { event: 'agreement_sign', event_category: 'general' },
      attach_card: { event: 'attach_card', event_category: 'general' },
      esia_registration: { event: 'esia_registration', event_category: 'general' },
    },
  },
  kbki: 3377,
  adspire: 'kredito24.ru',
  jivo: {
    link: '//cloud.craft-talk.com',
    name: 'webchat_kredito24',
    container: 'webchat-js-container',
  },
};

export const SAVE_COOKIES = [];
export const SAVE_COOKIES_MAP = {};
export const SAVE_COOKIES_TTL = 60; //days

export const SENTRY_ENVIRONMENT = 'production';
export const SENTRY_DSN =
  TENANT_DOMAIN === 'monedo'
    ? 'https://553f85b3c271464780047c45fb5f8236@sentry.brainysoft.ru/20'
    : 'https://d82941e8749c4c819acc30be6213c92a@sentry-ru.brainysoft.ru/5';
export const SENTRY_TRACE_SAMPLE_RATE = 0.1;

// export const DENY_CLICKTRACKER_LINK = 'https://shortio.shortcm.li/BWjT';
export const DENY_CLICKTRACKER_LINK = null;
