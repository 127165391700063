import * as defaultComponents from '../default';
import { Favicons } from './Favicons';
import { Footer } from './Footer';
import { LoanAppCreateWidget } from './Widgets/LoanAppCreateWidget';
import { Denied } from './Widgets/Denied';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...defaultComponents,
  Footer,
  Favicons,
  LoanAppCreateWidget,
  Denied,
};
