/* eslint-disable @typescript-eslint/no-empty-function */
import { authService, tokenService, clientService, cacheService as Storage } from '../../services';
import { AppContext } from '../../ctx';
import { useRouter } from 'next/router';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

const authContext = createContext({
  isAuthenticated: false,
  login: (token: string) => {},
  logout: () => {},
});

export const AuthProvider: FC<PropsWithChildren> = (props) => {
  const { methods } = AppContext.useContainer();
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkIsAuthenticated = async () => {
      const response = await clientService.getClient();
      if (response) {
        setIsAuthenticated(true);
      }
      setIsChecking(false);
    };
    checkIsAuthenticated();
  }, []);

  const login = (token: string) => {
    tokenService.setTokenToStorage(token);
    setIsAuthenticated(true);
    router.push('/');
  };

  const logout = async () => {
    setIsAuthenticated(false);
    await authService.signOut();
    tokenService.logout();
    await Storage.clear();
    router.push('/auth');
  };

  return (
    <>
      {!isChecking && (
        <authContext.Provider value={{ isAuthenticated, login, logout }}>{props.children}</authContext.Provider>
      )}
    </>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};
