export type DeferredPromiseT = ReturnType<typeof createDeferredPromise>;

export function createDeferredPromise() {
  let resolve;
  let reject;

  const promise = new Promise((thisResolve, thisReject) => {
    resolve = thisResolve;
    reject = thisReject;
  });

  return { promise, resolve, reject };
}
