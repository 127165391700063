import ru_RU from 'antd/lib/locale/ru_RU';
import en_US from 'antd/lib/locale/en_US';

export const getLocale = (localeCode) => {
  switch (localeCode) {
    case 'en':
      return en_US;
    case 'ru':
      return ru_RU;
  }
  console.log(`Undefined locale ${localeCode}`);
  return ru_RU;
};
