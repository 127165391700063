import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

import { css as original } from '@custom/defaults/theme/Form/Slider/styles';
import { merge } from 'lodash';

const { primary, primaryLight, white, black } = THEME_COLORS;
const { fontFamily } = VARIABLES;

export const css = merge(original, {
  numberValue: {
    color: black,
  },
  numberValueUnit: {
    color: black,
  },
  numberInput: {
    '& input': {
      color: black,
    },
  },
});

export default css;
