export {
  NODE_ENV_PRODUCTION,
  TENANT_DOMAIN,
  SMS_SEND_DELAY,
  STATUS_CHECK_INTERVAL,
  REFRESH_DATA_INTERVAL,
  DADATA_TOKEN,
  LOCALE,
  HEADER_INVERTED,
  MAX_DOWNLOAD_FILE_SIZE,
  MIN_FIAS_LVL,
  WDYR,
  SAVE_COOKIES,
  SAVE_COOKIES_MAP,
  SAVE_COOKIES_TTL,
  OPTIONS,
  INTEGRATIONS,
  SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE,
} from '@brainysoft/lk-custom/config';
