export enum StatefulProcessType {
  REGULAR_REPAYMENT = 'regular-repayment',
  INTEREST_REPAYMENT = 'interest-repayment',
  EARLY_REPAYMENT = 'early-repayment',
  ARBITRARY_REPAYMENT = 'arbitrary-repayment',
  PROLONGATION = 'prolongation',
  OFERTA_SIGN = 'ofertaSign',
  UPLOAD_PASSPORT = 'upload-passport',
  UPLOAD_CARD_PHOTO = 'upload-card-photo',
}
