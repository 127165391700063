import Link from 'next/link';
import * as React from 'react';
import { useTranslation } from '../../utils/useTranslation';
import { dateParser } from '../../helpers/dateHelper';
import CurrencyIcon from '../CurrencyIcon';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { priceFormat } from '../../helpers/priceFormat';
import { Popover } from 'antd';
import { Icon } from '../Icon';
// eslint-disable-next-line no-unused-vars
import { FundTransactionT } from '@brainysoft/lk-components';
import { LoadingSpinner } from '../LoadingSpinner';
import { Currency } from '../Currency';

interface IProps {
  paymentsList: FundTransactionT[];
  isLoading: boolean;
  type?: 'contract' | undefined;
}

export const PaymentsTable: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('PaymentsTable');
  const layoutClasses = useThemeStyles('Layout');
  const { t } = useTranslation();

  const { paymentsList, isLoading, type } = props;

  return (
    <div className={layoutClasses.tableWrapper}>
      <table className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th>{t('status')}</th>
            <th>{t('contractNumberTable')}</th>
            <th>{t('paymentDate')}</th>
            <th>{t('paymentMethod')}</th>
            <th>{t('paymentSumm')}</th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {!isLoading &&
            paymentsList.map((item, index) => {
              const isAccepted = item.isAccepted ? 'accepted' : 'notAccepted';
              return (
                <tr key={index}>
                  <td className={`${classes.fundTransactionStatus} ${isAccepted}`}>
                    {item.isAccepted && (
                      <Popover
                        title={''}
                        arrow={{ pointAtCenter: true }}
                        placement='topLeft'
                        content={t('payments:handled')}
                      >
                        <Icon type='check-circle' theme='filled' />
                      </Popover>
                    )}
                    {!item.isAccepted && (
                      <Popover
                        title={''}
                        arrow={{ pointAtCenter: true }}
                        placement='topLeft'
                        content={t('payments:notHandled')}
                      >
                        <Icon type='close-circle' theme='filled' />
                      </Popover>
                    )}
                  </td>
                  <td>
                    <span className={[classes.mobileonly, classes.mobileHeaderItem].join(' ')}>
                      {t('contractNumberTable')}
                    </span>
                    <Link href={'/contracts/[id]'} as={`/contracts/${item.contractId}`}>
                      <span className={classes.numberCell}>{item.contractName}</span>
                    </Link>
                  </td>
                  <td>
                    <span className={[classes.mobileonly, classes.mobileHeaderItem].join(' ')}>{t('paymentDate')}</span>
                    <span className={classes.dateCell}>{dateParser(item.date, true)}</span>
                  </td>
                  <td>
                    <span className={[classes.mobileonly, classes.mobileHeaderItem].join(' ')}>
                      {t('paymentMethod')}
                    </span>
                    <span className={classes.methodCell}>{item.paymentType}</span>
                  </td>
                  <td>
                    <span className={[classes.mobileonly, classes.mobileHeaderItem].join(' ')}>{t('paymentSumm')}</span>
                    <span className={classes.summCell}>
                      <Currency amount={item.amount} size='1.375rem' ratio={0.75} />
                    </span>
                  </td>
                </tr>
              );
            })}
          {!paymentsList.length && (
            <tr>
              <td className={classes.lastCell} colSpan={5}>
                <span className={classes.noData}>
                  {isLoading && <LoadingSpinner size={8} margin={4} />}
                  {!isLoading && type === 'contract' && t('payments:noPaymentsOnCurrentContractYet')}
                  {!isLoading && type !== 'contract' && t('payments:noPaymentsYet')}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
