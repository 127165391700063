import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import Color from 'color';

const { fontSizeLarge, generalBorderRadius, inputBorderColor } = VARIABLES;
const { greyText, greyLight } = THEME_COLORS;

export const css = {
  input: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: greyLight,
    borderRadius: generalBorderRadius,
    fontSize: `${fontSizeLarge} !important`,
    padding: '.75rem 1.25rem !important',
    height: 'auto',
    color: greyText,
    lineHeight: '24px !important',
    width: '100%',
    '&:not(.disabled)': {
      '&:hover, &:active': {
        borderColor: inputBorderColor,
      },
      '&:focus': {
        borderColor: inputBorderColor,
        boxShadow: `0 0 0 2px ${Color(inputBorderColor).rgb().fade(0.8).string()}`,
      },
    },
    '&.ant-input.ant-input-lg': {},
    '& + .ant-input-textarea-suffix': {
      position: 'absolute',
      right: '.75rem',
      top: '.875rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    input: {},
  },
};

export default css;
