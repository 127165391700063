import ReCAPTCHA from 'react-google-recaptcha';
import { FC, memo } from 'react';
import { CaptchaStoreContext } from '../../ctx/captcha-context';

export const CAPTCHA: FC = memo(() => {
  const { state } = CaptchaStoreContext.useContainer();

  const { siteKey, captchaActive, recaptchaRef, recaptchaTokenPromiseRef, recaptchaLoadPromiseRef } = state;

  if (!siteKey || !captchaActive) return <></>;
  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      size={'invisible'}
      sitekey={siteKey}
      onChange={(token) => {
        recaptchaTokenPromiseRef.current.resolve(token);
      }}
      asyncScriptOnLoad={() => {
        recaptchaLoadPromiseRef.current.resolve();
      }}
      hl={'ru'}
    />
  );
});
