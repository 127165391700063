import { FC, PropsWithChildren } from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';

interface IMessage {
  type: 'neutral' | 'info' | 'error' | 'warning' | 'success';
  size?: 'normal' | 'small';
  className?: string;
  style?: Record<string, any>;
}

export const Message: FC<PropsWithChildren<IMessage>> = (props) => {
  const classes = useThemeStyles('Message');

  const { type, size, className, style } = props;

  return (
    <div className={`${classes.message} ${type ?? ''} ${className ?? ''} ${size ?? ''}`} style={style}>
      {props.children}
    </div>
  );
};
