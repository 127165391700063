import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

const { primary, primaryLight, white } = THEME_COLORS;
const { fontFamily } = VARIABLES;

export const css = {
  slider: {
    marginLeft: '15px',
    marginRight: '15px',
    '& .ant-slider-rail': {
      height: '6px',
    },
    '& .ant-slider-track': {
      height: '6px',
      backgroundColor: primaryLight,
    },
    '& .ant-slider-handle': {
      width: '20px',
      height: '20px',
      marginTop: '-4px',
      borderColor: primaryLight,
      '&:before, &:after': {
        width: '16px !important',
        height: '16px !important',
        insetInlineStart: '2px !important',
        insetBlockStart: '2px !important',
      },
    },
    '& .ant-tooltip-opened': {
      borderColor: primary,
    },
    '&:hover': {
      '& .ant-slider-track': {
        backgroundColor: primary,
      },
    },
  },
  numberWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberLabel: {
    fontSize: '0.875rem',
    marginBottom: '.5rem',
  },
  numberValue: {
    cursor: 'auto',
    backgroundColor: white,
    border: 0,
    fontSize: '2rem',
    fontFamily: fontFamily,
    height: 'auto',
    width: 'auto',
    fontWeight: 'bold',
    color: primary,
    '& input': {
      width: 'auto',
      textAlign: 'center',
      fontFamily: fontFamily,
    },
  },
  numberValueUnit: {
    marginLeft: '.5rem',
    fontWeight: 'blod',
    color: primary,
  },
  numberInput: {
    fontSize: '4rem',
    height: 'auto',
    width: 'auto',
    border: 0,
    boxShadow: 'none',
    transition: 'none',
    '&:hover, &:focus': {
      border: 0,
      boxShadow: 'none',
    },
    '& input': {
      transition: 'none',
      height: 'auto !important',
      textAlign: 'center !important',
      fontFamily: fontFamily,
      // fontWeight: 'bold',
      color: `${primary} !important`,
      letterSpacing: '-0.03em',
    },
    '& .ant-input-number-handler-wrap': {
      display: 'none',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    numberInput: {
      fontSize: '3rem',
      lineHeight: 1,
    },
  },
};

export default css;
