import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { primary, white } from '../../../colors';
import { css as original } from '@custom/defaults/theme/Components/HeaderRegistration/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  headerWrap: {
    background: primary,
  },
  phoneNumber: {
    color: white,
  },
  phoneDetail: {
    color: white,
  },
});

export default css;
