export enum CreditInteractionsType {
  SIGN_OFFER = 'sign-offer',
  UPLOAD_PASSPORT = 'upload-passport',
  UPLOAD_CARD_PHOTO = 'upload-card-photo',
}

export const isInteraction = (interaction: string) => {
  const interactions: string[] = Object.entries(CreditInteractionsType).map(([key, value]) => value);
  return interactions.includes(interaction);
};
