import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { debounce } from 'lodash';
import { Button } from '../Button';
import { useTranslation } from '../../utils/useTranslation';

interface IActionButtonsProps {
  showForward: boolean;
  showBack: boolean;
  isForwardDisabled?: boolean;
  isForwardLoading?: boolean;
  isBackDisabled?: boolean;
  isBackLoading?: boolean;
  onClickForward?: () => void;
  onClickBack?: () => void;
  textForward?: string;
  textBack?: string;
  isMutating: boolean;
  buttonsFullWidth?: boolean;
}

const DEBOUNCE_TIME = 500;

export const StatefulProcessActions: React.FC<IActionButtonsProps> = (props) => {
  const classes = useThemeStyles('StatefulProcess');
  const { t } = useTranslation();

  const {
    showForward,
    showBack,
    onClickForward,
    onClickBack,
    textForward,
    textBack,
    isForwardDisabled,
    isBackDisabled,
    isForwardLoading,
    isBackLoading,
    isMutating,
    buttonsFullWidth = false,
  } = props;

  const forward = React.useCallback(
    debounce(
      (onClickForward) => {
        if (typeof onClickForward === 'function') onClickForward();
      },
      DEBOUNCE_TIME,
      { leading: true, trailing: false }
    ),
    []
  );

  const back = React.useCallback(
    debounce(
      (onClickBack) => {
        if (typeof onClickBack === 'function') onClickBack();
      },
      DEBOUNCE_TIME,
      { leading: true, trailing: false }
    ),
    []
  );

  return (
    <div
      className={
        buttonsFullWidth
          ? `${classes.fullWidthButton} ${classes.statefulProcessActionsWrapper}`
          : classes.statefulProcessActionsWrapper
      }
    >
      {showBack && (
        <Button
          size={'large'}
          disabled={isBackDisabled || isMutating}
          loading={isBackLoading || isMutating}
          onClick={() => back(onClickBack)}
        >
          {textBack ?? t('base:back')}
        </Button>
      )}
      {showForward && (
        <Button
          size={'large'}
          type='primary'
          disabled={isForwardDisabled || isMutating}
          loading={isForwardLoading || isMutating}
          onClick={() => forward(onClickForward)}
        >
          {textForward}
        </Button>
      )}
    </div>
  );
};
