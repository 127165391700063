import { useRouter } from 'next/router';
import { authorizedAccess } from '@brainysoft/lk-components';
import { FC, PropsWithChildren } from 'react';
import { getCustomComponents } from '../../utils/getCustomComponents';

export const AuthorizedContentWrapper: FC<PropsWithChildren<any>> = (props) => {
  const router = useRouter();
  if (!authorizedAccess(router.pathname)) return <>{props.children}</>;

  const Wrapper = getCustomComponents('AuthorizedContentWrapperComponent');

  return <Wrapper>{props.children}</Wrapper>;
};
