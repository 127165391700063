import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { disabledBackground, greyText, THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';

const { red } = THEME_COLORS;
const { generalBorderRadius } = VARIABLES;

export const css = {
  formItem: {
    '& .ant-form-item': {
      marginBottom: '1rem',
      flexDirection: 'column',
      '& .ant-form-item-row': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    '& .ant-form-item-label': {
      fontSize: '.875rem',
      lineHeight: 1,
      textAlign: 'left',
      '& > label': {
        alignItems: 'flex-start',
        whiteSpace: 'normal',
        fontSize: '.875rem',
        minHeight: 24,
        height: 'auto',
        paddingBottom: 4,
      },
    },
    '& .ant-form-item-children': {
      display: 'block',
    },
    '& .ant-form-explain, & .ant-form-item-explain': {
      fontSize: '0.75rem',
      marginTop: '.125rem',
      lineHeight: 1.2,
    },
    '&.error input, &.error textarea': {
      borderColor: `${red} !important`,
      '&:focus': {
        boxShadow: '0 0 0 2px rgba(240,20,20, 0.2) !important',
      },
    },
    '&.error .ant-picker input': {
      border: '0 !important',
      '&:focus': {
        boxShadow: 'none !important',
      },
    },
    '&.error .react-tel-input input[type=tel]': {
      borderColor: `${red} !important`,
      '&:focus': {
        boxShadow: '0 0 0 2px rgba(240,20,20, 0.2) !important',
      },
    },
    '&.error .ant-upload.ant-upload-select-picture-card': {
      borderColor: `${red} !important`,
    },
    '&.error .ant-upload.ant-upload-drag': {
      borderColor: `${red} !important`,
    },
    '& .static': {
      lineHeight: '24px !important',
      padding: '0.75rem 1.25rem !important',
      minHeight: 'calc(26px + 1.5rem)',
      color: greyText,
      backgroundColor: disabledBackground,
      pointerEvents: 'none',
      cursor: 'not-allowed',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  enterButton: {
    display: 'flex',
    '& .react-tel-input input[type=tel]': {
      borderRadius: `${generalBorderRadius} 0 0 ${generalBorderRadius} !important`,
    },
    '& .ant-input': {
      borderRadius: `${generalBorderRadius} 0 0 ${generalBorderRadius}`,
    },
    '&>.ant-btn': {
      height: '3.6rem',
      flex: '1 0 auto',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      borderRadius: `0 ${generalBorderRadius} ${generalBorderRadius} 0`,
    },
  },
  labeled: {
    '&>.ant-btn': {
      marginTop: '1.5em',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    formInput: {},
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    formItem: {
      '& .ant-form-item-label': {
        margin: 0,
        padding: 0,
        fontSize: '.875rem',
        lineHeight: 1,
        textAlign: 'left',
        '& > label': {
          fontSize: '.875rem',
        },
      },
    },
  },
};

export default css;
