import { css as original } from '@custom/defaults/theme/Components/AuthPage/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  authBackgroundImage: {
    backgroundPosition: 'center',
  },
});

export default css;
