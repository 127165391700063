import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { get } from 'lodash';
import { Col, Divider, Row } from 'antd';
import { CreditProductT } from '@brainysoft/lk-components';
import { useTranslation } from '../../utils/useTranslation';

interface IProps {
  creditProducts: CreditProductT[];
  active: number;
  onChange?(id): any;
  className?: string;
  inWidget?: boolean;
  showIfSingle?: boolean;
}

export const CreditProducts: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('CreditProducts');
  const { t } = useTranslation();
  const onChange = props.onChange && typeof props.onChange === 'function' ? props.onChange : () => {};
  const creditProducts = props.creditProducts;

  const className = props.className ? props.className : '';

  const handleClick = (id) => {
    onChange(id);
  };

  React.useEffect(() => {
    if (creditProducts.length === 1) {
      onChange(creditProducts[0].id);
    }
  }, [creditProducts]);

  const titleOnlyClass = creditProducts.reduce((hasDescription, item) => {
    return hasDescription || !!item.description;
  }, false)
    ? ''
    : 'titleOnly';

  const creditProductsQty = creditProducts.length || 1;
  const desktopColumnQty = creditProductsQty < 3 ? creditProductsQty : 3;

  const blockWidth = props.inWidget ? 24 : 24 / desktopColumnQty;

  return (
    <React.Fragment>
      {(props.showIfSingle || creditProducts.length > 1) && (
        <React.Fragment>
          {!props.inWidget && <Divider style={{ background: 'transparent' }} />}

          <h4 className={`${classes.header} ${props.inWidget ? 'inWidget' : ''}`}>
            {t('widgets:creditProducts.suggest')}:
          </h4>

          <div className={classes.creditProductsWrapper}>
            <Row gutter={8}>
              {creditProducts.map((item, index) => {
                const active = item.id == props.active ? 'selected' : '';
                return (
                  <React.Fragment key={index}>
                    {!props.inWidget && (
                      <Col xs={24} md={blockWidth}>
                        <div
                          className={`${classes.creditProduct} ${active} ${titleOnlyClass} ${className}`}
                          onClick={() => handleClick(item.id)}
                        >
                          <div className={`${classes.name} ${titleOnlyClass}`}>{item.label}</div>
                          {!!item.description && <div className={classes.description}>{item.description}</div>}
                        </div>
                      </Col>
                    )}
                    {props.inWidget && (
                      <Col xs={24} md={blockWidth}>
                        <div
                          className={`${classes.creditProduct} ${active} ${className} inWidget`}
                          onClick={() => handleClick(item.id)}
                        >
                          <div className={`${classes.name} inWidget`}>{item.label}</div>
                          {!!item.description && (
                            <div className={`${classes.description} inWidget`}>{item.description}</div>
                          )}
                        </div>
                      </Col>
                    )}
                  </React.Fragment>
                );
              })}
            </Row>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
