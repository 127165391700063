import Link from 'next/link';
import React, { RefObject } from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { LoadingSpinner } from '../LoadingSpinner';
import { ButtonShape } from 'antd/es/button';

interface IButtonProps extends Omit<ButtonProps, 'shape'> {
  ref?: any;
  shape?: IButtonShape;
  href?: string;
}

type IButtonShape = ButtonShape | 'rect';

// eslint-disable-next-line react/display-name
export const Button: React.FC<IButtonProps> = React.forwardRef((props, ref) => {
  if (props.href) {
    return <LinkButton {...props} ref={ref} href={props.href} content={props.children} />;
  }
  return <AButton {...props} ref={ref} />;
});

interface ILinkButtonProps extends IButtonProps {
  href: string;
  content: any;
}

// eslint-disable-next-line react/display-name
const LinkButton: React.FC<ILinkButtonProps> = React.forwardRef((props, ref) => {
  const { href, ...buttonProps } = props;

  return (
    <Link href={props.href} passHref>
      <AButton ref={ref} {...buttonProps}>
        {props.content}
      </AButton>
    </Link>
  );
});

// eslint-disable-next-line react/display-name
const AButton: React.FC<IButtonProps> = React.forwardRef((props, ref) => {
  const classes = useThemeStyles('Button');

  const { loading, className = '', shape, icon, ...buttonProps } = props;
  const buttonIcon = loading ? (
    <LoadingSpinner
      size={8}
      margin={2}
      spotNumber={2}
      delayRatio={2}
      className={buttonProps.type === 'primary' && !buttonProps.disabled ? 'white' : 'grey'}
    />
  ) : (
    icon
  );

  return (
    <AntdButton
      {...buttonProps}
      ref={ref as RefObject<any>}
      className={`${classes.button} ${className}`}
      shape={shape ? (shape !== 'rect' ? shape : undefined) : 'round'}
      icon={buttonIcon}
    >
      {props.children}
    </AntdButton>
  );
});
