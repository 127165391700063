import * as React from 'react';
import { cacheService as Storage } from '@brainysoft/lk-components';
import { useInterval } from './useInterval';
import { PAYMENT_CHECK_INTERVAL } from '../const';
import dayjs from 'dayjs';

const timerPrefix = 'timer:';

export const useTimer = (key) => {
  const [isExpired, setIsExpired] = React.useState(false);
  const [checkInterval, setCheckInterval] = React.useState<number | null>(null);

  React.useEffect(() => {
    const checkTimer = async () => {
      const timer = await Storage.getItem(`${timerPrefix}:${key}:ttl`);
      if (timer) {
        setCheckInterval(PAYMENT_CHECK_INTERVAL);
      } else {
        setIsExpired(true);
      }
    };
    checkTimer();
  }, []);

  useInterval(() => {
    const checkTtl = async () => {
      const ttl = (await Storage.getItem(`${timerPrefix}:${key}:ttl`)) as string;
      if (dayjs().isBefore(dayjs(Number(ttl)))) {
        setIsExpired(false);
      } else {
        await Storage.removeItem(`${timerPrefix}:${key}:ttl`);
        setIsExpired(true);
        setCheckInterval(null);
      }
    };
    checkTtl();
  }, checkInterval);

  const setTimer = async (timeSec) => {
    const timer = await Storage.getItem(`${timerPrefix}:${key}:ttl`);
    if (!timer) {
      await Storage.setItem(`${timerPrefix}:${key}:ttl`, dayjs().add(timeSec, 's').valueOf());
      setCheckInterval(PAYMENT_CHECK_INTERVAL);
    }
  };

  return { isExpired, setTimer };
};
